import { blinkConstants } from '@thoughtspot/blink-constants';
import { BlinkContextOptions } from '@thoughtspot/blink-context';
import { getDaysFromNow } from '@thoughtspot/date-format-util';
import { translate } from '@thoughtspot/i18n';
import { jsonConstants } from '@thoughtspot/json-constants';
import * as session from '@thoughtspot/session-service';
import { ISessionInfo } from '@thoughtspot/session-service';
import { isBoolean } from 'lodash';
import {
    UpdateIsFirstTimeEnableVersionHistoryUserDocument,
    UpdateIsFirstTimeEnableVersionHistoryUserMutation,
    UpdateIsFirstTimeEnableVersionHistoryUserMutationVariables,
    UpdateIsFirstTimeSpotIqUserDocument,
    UpdateIsFirstTimeSpotIqUserMutation,
    UpdateIsFirstTimeSpotIqUserMutationVariables,
    UpdateIsFirstTimeVersionHistoryUserDocument,
    UpdateIsFirstTimeVersionHistoryUserMutation,
    UpdateIsFirstTimeVersionHistoryUserMutationVariables,
    UpdateIsSlackInitiallyConnectedByUserDocument,
    UpdateIsSlackInitiallyConnectedByUserMutation,
    UpdateIsSlackInitiallyConnectedByUserMutationVariables,
    UpdateVersionPreferenceDocument,
    UpdateVersionPreferenceMutation,
    UpdateVersionPreferenceMutationVariables,
} from '/@services/generated/graphql-types';
import { useMutation } from '../../../utils/error-handling/apollo-hooks-wrappers/custom-apollo-hooks';
import { flags } from './flags-service';

export function initSessionService(sessionInfo: ISessionInfo) {
    session.setSessionInfo(sessionInfo, flags);
}

export function getUserDisplayName(): string {
    const sessionInfo = session.getSessionInfo();
    return sessionInfo ? sessionInfo.userDisplayName : '';
}

export function getUserExpirationTime(): number {
    const sessionInfoObject = session.getSessionInfo();
    return sessionInfoObject ? sessionInfoObject.userExpirationTime : null;
}

export function getUserName(): string {
    const sessionInfo = session.getSessionInfo();
    return sessionInfo ? sessionInfo.userName : '';
}

export function flagMixpanelDNT(params: Record<string, string>): void {
    const queryParams = new URLSearchParams(params);
    const command = `/callosum/v1/session/mixpaneldnt?${queryParams.toString()}`;
    fetch(command);
}

export const useUpdateIsFirstTimeSpotIQUser = () => {
    const [updateIsFirstTimeSpotIQUser, { data, error, loading }] = useMutation<
        UpdateIsFirstTimeSpotIqUserMutation,
        UpdateIsFirstTimeSpotIqUserMutationVariables
    >(UpdateIsFirstTimeSpotIqUserDocument);

    return {
        data: data?.Session__updateIsFirstTimeSpotIQUser,
        error,
        loading,
        updateIsFirstTimeSpotIQUser: (isFirstTimeSpotIQUser: boolean) =>
            updateIsFirstTimeSpotIQUser({
                variables: {
                    isFirstTimeSpotIQUser,
                },
            }),
    };
};

export const useUpdateIsSlackInitiallyConnectedByUser = () => {
    const [
        updateIsSlackInitiallyConnectedByUser,
        { data, error, loading },
    ] = useMutation<
        UpdateIsSlackInitiallyConnectedByUserMutation,
        UpdateIsSlackInitiallyConnectedByUserMutationVariables
    >(UpdateIsSlackInitiallyConnectedByUserDocument);

    return {
        data: data?.Session__updateIsSlackInitiallyConnectedByUser,
        error,
        loading,
        updateIsSlackInitiallyConnectedByUser: (
            isSlackInitiallyConnectedByUser: boolean,
        ) =>
            updateIsSlackInitiallyConnectedByUser({
                variables: {
                    isSlackInitiallyConnectedByUser,
                },
            }),
    };
};

export const useUpdateIsFirstTimeVersionHistoryUser = () => {
    const [
        updateIsFirstTimeVersionHistoryUser,
        { data, error, loading },
    ] = useMutation<
        UpdateIsFirstTimeVersionHistoryUserMutation,
        UpdateIsFirstTimeVersionHistoryUserMutationVariables
    >(UpdateIsFirstTimeVersionHistoryUserDocument);

    return {
        data: data?.Session__updateIsFirstTimeVersionHistoryUser,
        error,
        loading,
        updateIsFirstTimeVersionHistoryUser: (
            isFirstTimeVersionHistoryUser: boolean,
        ) =>
            updateIsFirstTimeVersionHistoryUser({
                variables: {
                    isFirstTimeVersionHistoryUser,
                },
            }),
    };
};

export const useUpdateIsFirstTimeEnableVersionHistoryUser = () => {
    const [
        updateIsFirstTimeEnableVersionHistoryUser,
        { data, error, loading },
    ] = useMutation<
        UpdateIsFirstTimeEnableVersionHistoryUserMutation,
        UpdateIsFirstTimeEnableVersionHistoryUserMutationVariables
    >(UpdateIsFirstTimeEnableVersionHistoryUserDocument);

    return {
        data: data?.Session__updateIsFirstTimeEnableVersionHistoryUser,
        error,
        loading,
        updateIsFirstTimeEnableVersionHistoryUser: (
            isFirstTimeEnableVersionHistoryUser: boolean,
        ) =>
            updateIsFirstTimeEnableVersionHistoryUser({
                variables: {
                    isFirstTimeEnableVersionHistoryUser,
                },
            }),
    };
};

export const useUpdateNotAFirstTimeSpotIQUser = (
    useAppContext: () => BlinkContextOptions,
) => {
    const { sessionService } = useAppContext();
    const { getPreference, setPreference } = sessionService;
    const { updateIsFirstTimeSpotIQUser } = useUpdateIsFirstTimeSpotIQUser();
    let isFirstTimeSpotIQUser = getPreference(
        blinkConstants.preferenceKeys.IS_FIRST_TIME_SPOT_IQ_USER,
    );
    isFirstTimeSpotIQUser =
        !isBoolean(isFirstTimeSpotIQUser) || isFirstTimeSpotIQUser;

    const setAsNotAFirstTimeSpotIQUser = () => {
        setPreference(
            blinkConstants.preferenceKeys.IS_FIRST_TIME_SPOT_IQ_USER,
            false,
        );
        updateIsFirstTimeSpotIQUser(false);
    };
    return { isFirstTimeSpotIQUser, setAsNotAFirstTimeSpotIQUser };
};

export const useUpdateNotAFirstTimeVersionHistoryUser = (
    useAppContext: () => BlinkContextOptions,
) => {
    const { sessionService } = useAppContext();
    const { getPreference, setPreference } = sessionService;
    const {
        updateIsFirstTimeVersionHistoryUser,
    } = useUpdateIsFirstTimeVersionHistoryUser();
    let isFirstTimeVersionHistoryUser = getPreference(
        blinkConstants.preferenceKeys.IS_FIRST_TIME_VERSION_HISTORY_USER,
    );
    isFirstTimeVersionHistoryUser =
        !isBoolean(isFirstTimeVersionHistoryUser) ||
        isFirstTimeVersionHistoryUser;

    const setAsNotAFirstTimeVersionHistoryUser = () => {
        setPreference(
            blinkConstants.preferenceKeys.IS_FIRST_TIME_VERSION_HISTORY_USER,
            false,
        );
        updateIsFirstTimeVersionHistoryUser(false);
    };
    return {
        isFirstTimeVersionHistoryUser,
        setAsNotAFirstTimeVersionHistoryUser,
    };
};

export const useUpdateNotAFirstTimeEnableVersionHistoryUser = (
    useAppContext: () => BlinkContextOptions,
) => {
    const { sessionService } = useAppContext();
    const { getPreference, setPreference } = sessionService;
    const {
        updateIsFirstTimeEnableVersionHistoryUser,
    } = useUpdateIsFirstTimeEnableVersionHistoryUser();
    let isFirstTimeEnableVersionHistoryUser = getPreference(
        blinkConstants.preferenceKeys.IS_FIRST_TIME_ENABLE_VERSION_HISTORY_USER,
    );
    isFirstTimeEnableVersionHistoryUser =
        !isBoolean(isFirstTimeEnableVersionHistoryUser) ||
        isFirstTimeEnableVersionHistoryUser;

    const setAsNotAFirstTimeEnableVersionHistoryUser = () => {
        setPreference(
            blinkConstants.preferenceKeys
                .IS_FIRST_TIME_ENABLE_VERSION_HISTORY_USER,
            false,
        );
        updateIsFirstTimeEnableVersionHistoryUser(false);
    };
    return {
        isFirstTimeEnableVersionHistoryUser,
        setAsNotAFirstTimeEnableVersionHistoryUser,
    };
};

export const useUpdateVersionPreferenceMutation = () => {
    const [
        updateVersionPreferenceMutation,
        { data, error, loading },
    ] = useMutation<
        UpdateVersionPreferenceMutation,
        UpdateVersionPreferenceMutationVariables
    >(UpdateVersionPreferenceDocument);

    return {
        data: data?.Session__updateVersionPreference,
        error,
        loading,
        updateVersionPreference: (
            isAnswerV2Preferred: boolean,
            isSpotIQV2Preferred: boolean,
            isDataPanelV2Preferred: boolean,
            isModularHomeExperiencePreferred: boolean,
            isConvAssistExperiencePreferred: boolean,
            isKPIAnomaliesPrefered: boolean,
            chartContextMenuExperiencePreferred: string,
        ) =>
            updateVersionPreferenceMutation({
                variables: {
                    isAnswerV2Preferred,
                    isSpotIQV2Preferred,
                    isDataPanelV2Preferred,
                    isModularHomeExperiencePreferred,
                    isConvAssistExperiencePreferred,
                    isKPIAnomaliesPrefered,
                    chartContextMenuExperiencePreferred,
                },
            }),
    };
};

export const getProductName = (): string => {
    const productName = session.getProductNameWrapper();
    return productName === session.DEFAULT_PRODUCT_NAME
        ? translate('THOUGHTSPOT')
        : productName;
};

export const getCanChangePassword = function() {
    const sessionInfo = session.getSessionInfo();
    if (!sessionInfo) {
        return false;
    }
    return sessionInfo[jsonConstants.CAN_CHANGE_PASSWORD];
};

export const trialDaysLeft = (): number => {
    const expiryTimestamp: number = session.trialDaysLeftWrapper();
    return expiryTimestamp > 0
        ? Number(getDaysFromNow(expiryTimestamp))
        : expiryTimestamp;
};

// show teams tab if teams edition is enabled
// and getSubscriptionType is defined, currently subscription type has values FREE_TRIAL and TEAMS_EDITION
// teams tab is enabled for both.
export const canShowTeamsTab: () => boolean = () =>
    session.isTeamsEnabled() &&
    !!session.getSubscriptionType() &&
    [
        session.SubscriptionType.FREE_TRIAL,
        session.SubscriptionType.TEAMS_EDITION,
    ].includes(session.getSubscriptionType()) &&
    !session.isOrgsOnFTEnabled();

export * from '@thoughtspot/session-service';
