import { jsonConstants } from '@thoughtspot/json-constants';
import { IconID } from '@thoughtspot/radiant-react/widgets/icon';
import { LoadingIndicator } from '@thoughtspot/radiant-react/widgets/loading-indicator';
import {
    getAccessToken,
    getCurrentOrgId,
    getOktaUserId,
    getUserPrivileges,
} from '@thoughtspot/session-service';
import { getClientId, getIdpDomain } from '@thoughtspot/system-config-service';
import React, { Suspense } from 'react';
import { SessionService } from '/@contexts/global-app-context';
import { isEmbeddedApp } from '/@utils/embed-base.util';
import { TopNav } from '../../microfrontends/global-nav';
import { UserMenuContainerWithInvite } from '../user-menu/user-menu.container';
import { useAnalystStudioMutation } from './custom-action-hooks/useAnalystStudioCustomHook';
import { NavSearchBarContainer } from './nav-search-bar.container';

// TODO(Sreeni): Figure out type passing from MFEs
export type NavLinkURI = {
    uri: string;
    linkTarget?: '_blank' | '_self';
    routerURI: never;
    callback: never;
    isReactShellSupported: boolean;
};

export type NavLinkCallback = {
    callback: () => void;
    routerURI?: string;
    uri: never;
    linkTarget: never;
    isReactShellSupported: never;
};

export declare type NavLinkWithTitleIconAndCallback = NavLinkCallback & {
    titleIconId: IconID;
};

export type NavLinkConfig = {
    name: string;
    id?: string;
    icon?: IconID;
    description?: string;
    target: NavLinkURI | NavLinkCallback | NavLinkWithTitleIconAndCallback;
};

export interface QuickLinkItem {
    id: string;
    label: string;
    action: () => void;
    path: string;
    hide?: boolean;
}

interface GlobalNavProps {
    sessionService?: SessionService;
    homeButtonConfig: NavLinkConfig;
    renderSearchBar?: boolean;
    applicationSwitcherConfig?: NavLinkConfig[];
    searchBar?: React.ReactChild;
    userProfile?: React.ReactChild;
    activeApplicationID?: string;
    triggerV1Logout?: () => void;
    shouldShowPendoHelp: boolean;
    useAddUserToModeMutation: any;
    useGetAnalystStudioAccessInfoLazyQuery: any;
    isCopilotEnabled?: boolean;
    customQuickLinks?: QuickLinkItem[];
    isEmbedded?: boolean;
}
export const GlobalNav: React.FC<GlobalNavProps> = props => {
    // take analyst studio hooks from props.
    const {
        useAddUserToModeMutation,
        useGetAnalystStudioAccessInfoLazyQuery,
    } = props;

    const appSwitcherProps = { ...props };

    // Initialize mutation functions for Analyst Studio
    const { onAnalystStudioClicked } = useAnalystStudioMutation(
        getAccessToken, // Access token for authentication
        getOktaUserId, // User ID from Okta
        getUserPrivileges, // User privileges for access control
        getCurrentOrgId, // Current organization ID
        useAddUserToModeMutation, // Mutation hook for adding user to a mode
        useGetAnalystStudioAccessInfoLazyQuery, // Lazy query hook for fetching Analyst Studio access info
        getIdpDomain, // IDP domain for configuration
        getClientId, // Client ID for configuration
    );

    // Find the index of the configuration object related to Analyst Studio
    const index = props.applicationSwitcherConfig.findIndex(
        config => config.id === jsonConstants.ANALYST_STUDIO_APP_ID,
    );

    // Retrieve the Analyst Studio configuration if it exists
    const analystStudioConfig =
        index !== -1 ? props.applicationSwitcherConfig[index] : null;

    // If the Analyst Studio configuration is found, update the callback if necessary
    if (analystStudioConfig) {
        // Set a new callback function for Analyst Studio
        analystStudioConfig.target.callback = () => {
            onAnalystStudioClicked();
        };
        (analystStudioConfig.target as NavLinkWithTitleIconAndCallback).titleIconId =
            IconID.OPEN_IN_NEW_TAB;
        // Update the configuration in the array
        appSwitcherProps.applicationSwitcherConfig[index] = analystStudioConfig;
    }

    const { isEmbedded = isEmbeddedApp() } = props;

    return (
        <TopNav
            searchBar={
                !props.isCopilotEnabled &&
                props.renderSearchBar && (
                    <Suspense
                        fallback={
                            <LoadingIndicator.Global>
                                <></>
                            </LoadingIndicator.Global>
                        }
                    >
                        <NavSearchBarContainer />
                    </Suspense>
                )
            }
            userProfile={
                <UserMenuContainerWithInvite
                    onEmailAdd={null}
                    triggerV1Logout={props.triggerV1Logout}
                />
            }
            isEmbedded={isEmbedded}
            disableQuickLinks={props.isCopilotEnabled}
            {...props}
        />
    );
};
export default GlobalNav;
