import { useEmbedContext } from '@thoughtspot/blink-context';
import { useTranslation } from '@thoughtspot/i18n';
import { create } from '@thoughtspot/logger';
import { collectEvent, UserAction } from '@thoughtspot/metrics';
import { MutedAlert } from '@thoughtspot/radiant-react/widgets/alert/muted/muted-alert';
import React, { useEffect } from 'react';
import { useNavigationType } from 'react-router-dom';
import {
    Action,
    getDisabledActionReason,
    getDisabledActions,
    getHiddenActions,
    getVisibleActions,
    shouldShowAction,
} from '/@utils/embed.util';
import { Vertical } from '../layouts';

interface ErrorBoundaryState {
    hasError: boolean;
}
interface ErrorBoundaryProp {
    hasRouter?: boolean;
    children?: any;
}

const logger = create('error-boundary');
const ERROR_PAGE_SHOWN = 'ERROR_PAGE_SHOWN';

export const FallbackComponent = () => {
    const { t } = useTranslation();
    const embedConfig = useEmbedContext();
    const showPrimaryAction = embedConfig.isEmbedded
        ? shouldShowAction(
              getVisibleActions(embedConfig),
              getHiddenActions(embedConfig),
              Action.ReportError,
          )
        : true;
    const disablePrimaryAction = getDisabledActions(embedConfig).has(
        Action.ReportError,
    );
    const disabledActionReason = getDisabledActionReason(embedConfig);

    return (
        <Vertical
            hAlignContent="center"
            vAlignContent="center"
            data-testid="fallback-card"
            grow
        >
            <MutedAlert
                global
                imageVisible
                title={t('answerInitError.title')}
                description={t('answerInitError.detail')}
                {...(showPrimaryAction && {
                    primaryAction: {
                        label: t('alertService.REPORT'),
                        onClick: () => {
                            window.open(
                                'https://community.thoughtspot.com/customers/s/topiccatalog',
                            );
                        },
                        isDisabled: disablePrimaryAction,
                        ...(disablePrimaryAction && {
                            tooltip: disabledActionReason,
                        }),
                    },
                })}
                secondaryAction={{
                    label: t('answerInitError.retry'),
                    onClick: () => {
                        window.location.reload();
                    },
                }}
            />
        </Vertical>
    );
};

/**
 * ErrorBoundaryUtil uses useNavigationType to reset error state on navigation change.
 *
 * NOTE: Navigation hooks can only be used if error boundary is inside router.
 * If ErrorBoundary is used in those components which are used outside of react router and in V1
 * then set hasRouter value as per their mounting env
 */
export const ErrorBoundaryUtil = (props: any) => {
    const { hasRouter = false, hasError, children, resetError } = props;
    const navigationType = hasRouter && useNavigationType();

    useEffect(() => {
        // When route or hash changes, call resetError to clear the error state
        if (
            hasRouter &&
            hasError &&
            navigationType &&
            navigationType !== 'POP'
        ) {
            resetError();
        }
    }, [navigationType]);

    if (hasError) {
        return <FallbackComponent />;
    }

    return children;
};

// Have to use class component for this as there is no hook
// for componentDidCatch https://stackoverflow.com/a/59932120
// so for now doesn't seem possible to turn it into a functional component

export class ErrorBoundary extends React.Component<
    ErrorBoundaryProp,
    ErrorBoundaryState
> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        const eventMetrics: UserAction = {
            type: ERROR_PAGE_SHOWN,
            name: ERROR_PAGE_SHOWN,
            tags: [],
            messageParams: null,
            shouldSendEventToExperimentationService: false,
            experimentationProject: null,
        };
        collectEvent(eventMetrics);
        logger.error(error, errorInfo);
    }

    resetError = () => {
        this.setState({ hasError: false });
    };

    render() {
        return (
            <ErrorBoundaryUtil
                hasError={this.state.hasError}
                resetError={this.resetError}
                {...this.props}
            />
        );
    }
}
