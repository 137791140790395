import { AppLogoType } from '@thoughtspot/custom-style-service';
import {
    Wizard,
    WizardStepProps,
} from '@thoughtspot/radiant-react/widgets/wizard';
import React from 'react';
import { useAppContext } from '/@contexts/global-app-context';
import { NonEmbedAccessSwitchOrgContainer } from './non-embed-access-switch-org.container';

export const NonEmbedAccessWizard = () => {
    const { customStylingService } = useAppContext();
    const logo = customStylingService
        .getConfig()
        ?.getAppLogoUrl(AppLogoType.DEFAULT);
    const stepComponent: React.ReactNode = <NonEmbedAccessSwitchOrgContainer />;
    const wizardSteps: WizardStepProps[] = [
        {
            stepComponent,
        },
    ];

    return (
        <Wizard
            headerLogoPath={logo}
            currentStep={0}
            wizardSteps={wizardSteps}
        />
    );
};
