import { useTranslation } from '@thoughtspot/i18n';
import { Button } from '@thoughtspot/radiant-react/widgets/button';
import {
    Horizontal,
    Vertical,
} from '@thoughtspot/radiant-react/widgets/layout';
import { Select } from '@thoughtspot/radiant-react/widgets/select';
import {
    OptionsGroup,
    SelectOption,
} from '@thoughtspot/radiant-react/widgets/select/base-select';
import { Typography } from '@thoughtspot/radiant-react/widgets/typography';
import _ from 'lodash';
import React from 'react';
import styles from './non-embed.module.scss';

export interface OrgMetadata {
    orgId: string;
    orgName: string;
    nonEmbedAccess: boolean;
}
export interface NonEmbedAccessSwitchOrgProps {
    isEnableSelectiveUserAccessRestriction: boolean;
    selectedOrgId: string;
    orgList: OrgMetadata[];
    handleOrgChange: (data: SelectOption) => void;
    switchOrgCb: () => void;
}

export enum NonEmbedAccessSwitchOrgDataTestId {
    DISPLAY_MESSAGE = 'display-message',
    ORG_SWITCHER = 'org-switcher',
    SWITCH_BUTTON = 'switch-button',
}

export const NonEmbedAccessSwitchOrg = ({
    isEnableSelectiveUserAccessRestriction,
    selectedOrgId,
    orgList,
    handleOrgChange,
    switchOrgCb,
}: NonEmbedAccessSwitchOrgProps) => {
    const { t } = useTranslation();

    const orgOptions: OptionsGroup[] = [
        {
            title: '',
            options: orgList.map((org: OrgMetadata) => {
                return {
                    id: org.orgId,
                    label: org.orgName,
                    disabled:
                        isEnableSelectiveUserAccessRestriction &&
                        !org.nonEmbedAccess,
                };
            }),
        },
    ];

    const userHasSwitchableOrg = isEnableSelectiveUserAccessRestriction
        ? orgOptions[0].options.some(
              selectOption => selectOption.disabled !== true,
          )
        : orgList.length > 1;

    return (
        <Vertical className={styles.container}>
            {userHasSwitchableOrg ? (
                <Typography
                    variant="h2"
                    className={styles.headerText}
                    data-testid={
                        NonEmbedAccessSwitchOrgDataTestId.DISPLAY_MESSAGE
                    }
                >
                    {t('NON_EMBED_FULL_APP_ACCESS_SWITCH_ORG')}
                </Typography>
            ) : (
                <Typography
                    variant="h2"
                    className={styles.headerText}
                    data-testid={
                        NonEmbedAccessSwitchOrgDataTestId.DISPLAY_MESSAGE
                    }
                >
                    {t('NON_EMBED_FULL_APP_ACCESS')}
                </Typography>
            )}
            {userHasSwitchableOrg && (
                <Horizontal
                    className={styles.columnSelect}
                    spacing="d"
                    vAlignContent="center"
                >
                    <div className={styles.orgSelect}>
                        <Select.Dropdown
                            id={NonEmbedAccessSwitchOrgDataTestId.ORG_SWITCHER}
                            optionsGroups={orgOptions}
                            selectedOptions={[selectedOrgId]}
                            onChange={handleOrgChange}
                            placeholder={t(
                                'nonEmbedAccessSwitchOrg.placeholder',
                            )}
                            isSearchEnabled
                            hasGrayBackground
                            data-testid={
                                NonEmbedAccessSwitchOrgDataTestId.ORG_SWITCHER
                            }
                        />
                    </div>
                    <Button
                        onClick={() => switchOrgCb()}
                        isDisabled={_.isEmpty(selectedOrgId)}
                        text={t('nonEmbedAccessSwitchOrg.primaryButton')}
                        data-testid={
                            NonEmbedAccessSwitchOrgDataTestId.SWITCH_BUTTON
                        }
                    />
                </Horizontal>
            )}
        </Vertical>
    );
};
