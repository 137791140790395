import {
    blinkConstants,
    CONNECTOR_DISPLAY_NAME,
} from '@thoughtspot/blink-constants';
import { EmbedContextOptions, QueryParams } from '@thoughtspot/blink-context';
import {
    ContextMenuInputPoints,
    ContextMenuInputValue,
} from '@thoughtspot/chart-common';
import { Chart } from '@thoughtspot/chart-lib';
import { ChartDataRenderError } from '@thoughtspot/chart-lib/lib/core/chart-data-validation';
import {
    getChartType,
    getColumns,
    getVisualizedColumns,
} from '@thoughtspot/chart-lib/lib/core/chart-model-utils';
import {
    getEndEpoch,
    getStartEpoch,
} from '@thoughtspot/custom-calendar-service';
import { addClientQueryParamsToPath } from '@thoughtspot/embrace/lib/src/services/nav-service/nav-service';
import { translate } from '@thoughtspot/i18n';
import { create } from '@thoughtspot/logger';
import { ActionMenuItem } from '@thoughtspot/radiant-react/widgets/action-menu';
import {
    CircularLoaderSize,
    CircularStepLoaderSteps,
} from '@thoughtspot/radiant-react/widgets/radiant-candidate/circular-step-loader';
import {
    canManageWorksheetViewsTables,
    hasA3AnalysisPrivileges,
    hasDownloadAnswerPrivileges,
    isMonitorEnabledOnTse,
    isRolesEnabled,
    SessionService,
} from '@thoughtspot/session-service';
import {
    isDateColumn,
    isTimeColumn,
} from '@thoughtspot/visualization-column-util';
import _ from 'lodash';
import React from 'react';
import { Subject } from 'rxjs';
import { AnswerAction } from 'src/components/answer/answer-content-header/answer-actions/answer-actions-interfaces';
import { ControlMenuActionTypes } from 'src/components/axis-menu/axis-menu.util';
import { ColumnEditorTab } from 'src/components/column-editor/column-editor.util';
import { TABS } from 'src/components/column-editor/table-column-editor/table-column-editor-tab-display';
import {
    ContextMenuOption,
    GenericMenuItemType,
} from 'src/components/viz-context-menu/viz-context-menu.util';
import {
    AccessMode,
    ActionAvailability,
    ActionDetail,
    ActionTypes,
    Answer,
    AnswerColumn,
    AnswerEditSession,
    AssociatedActionContext,
    ChartType,
    ChartViz,
    DisplayMode,
    EmptyAnswer,
    EmptyAnswerStateEnum,
    GetSelectedColumnsQuery,
    GetTableWithDataDocument,
    HeadlineViz,
    LoadingState,
    ObjectPermission,
    PhraseType,
    RAnalysisViz,
    RecognizedToken,
    SageSearchResponse,
    TableViz,
    Visualization,
    VisualizationQueryStatus,
    VizColumn,
} from '/@services/generated/graphql-types';
import {
    FLAGS,
    flags as envFlags,
} from '/@services/system/config-service/flags-service';
import { getHeadlineColumns } from '/@services/visualization/headline/headline-service.util';
import { ShowSageTokensConfig } from '/@src/components/conv-assist-answer/conv-assist-answer.util';
import { VizLoadingStatusConfig } from '/@src/components/query-statistics-loader/liveboard-viz-query-status.interface';
import { AnalysisStatus } from '/@src/components/spotiq-modal/spotiq-modal.util';
import {
    Action,
    ActionState,
    DataSourceVisualMode,
    getDisabledActionReason,
    getDisabledActions as getDisabledActionsForEmbed,
    getEmbedActionStateMap,
    getHiddenActions,
    getVisibleActions,
    shouldHideForEmbed,
} from '/@utils/embed.util';
import { DefinedSizes } from '../../components/answer/answer-content-header/answer-actions/menu-button/size-select/size-select-interfaces';
import { AnswerAlertBanner } from '../../utils/answer-banner.util';
import {
    ApolloClient,
    gql,
    PureQueryOptions,
    Reference,
} from '../../utils/error-handling/apollo-hooks-wrappers/custom-apollo-hooks';
import {
    evictLBChartVizCache,
    readFromLBChartVizCache,
} from '../../utils/lb-chartViz-caching-service';
import { bachSessionKeyQuery } from '../bach/bach.util';
import { getSession } from '../stateful/stateful-service-hooks.util';
import { updateAxisConfigColumns } from '../visualization/client-state-service.util';

const logger = create('Answer.Util');

const DIG_UPTO_LEVEL = 5;

/**
 * Breakpoints for circular step loader
 * loaderSize - Used to decide the size of the loader to be displayed
 * loaderText - Used to decide whether to display the loading text or not based on the tile dimensions
 */
const breakpointsForStepLoader = {
    loaderSize: {
        minHeightForSmallLoader: 143,
        minHeightForNormalLoader: 223,
    },
    loaderText: {
        minRequiredHeight: 303,
        minRequiredWidth: 358,
    },
};

/*
 * The type Visualization is extended to create the actual visualization types we use in the app.
 * The below type is a reflection of any possible final visualization that you would see in the
 * real world.
 */
export type FinalVisualization =
    | ChartViz
    | TableViz
    | HeadlineViz
    | RAnalysisViz;

export enum AnswerPageActionsEnum {
    SET_ANSWER_SESSION,
    SET_INSIGHT_SPEC_ID,
    SET_ANSWER_ACTIONS,
    SET_EMPTY_STATE,
    SET_ANSWER_PANEL_STATE,
    SET_CHART,
    SET_COLUMN,
    SET_AXIS,
    SET_ANSWER_DESCRIPTION_CLASSNAME,
    SET_VIZ_CONFIG_VALIDATION_ERROR,
    SET_COLUMN_ID_TO_BE_REMOVED,
    SET_CLIPBOARD_TOAST,
    SET_TABLE_EDITOR_TAB_ID,
    SET_PINBOARD_VIZ_EDIT_CONTEXT,
    SET_ANSWER_MODE,
    SET_ANSWER_PAGE_CONTEXT,
    SET_TABLEDATA_START_OFFSET,
    SET_SELECTED_METRIC,
    SET_TABLE_LOADING,
    SET_LEFT_PANEL_COLLAPSED_STATE,
    SET_PATH_ANALYSIS_ACTIVE,
    SET_HIDDEN_MENU_ACTIONS,
    SET_IS_ACTION_MENU_HIDDEN,
    SET_IS_ANSWER_LOADING,
    SET_MENU_ACTIONS,
    SET_MENU_ACTIONS_DISABLED_CONFIG,
    SET_BUTTON_ACTIONS,
    SET_ACTIONS_RIGHT_TO_MORE_BUTTON,
    SET_ANSWER_TITLE_DISABLED,
    SET_ANSWER_HEAD_DISABLED,
    SET_EDIT_TITLE_DISABLED,
    SET_IS_CUSTOM_ACTIONS_HIDDEN,
    SET_TILE_LAYOUT_SIZE,
    SET_ACTION_HANDLERS,
    SET_SHOW_ACTIONS,
    SET_IS_PRESENTED,
    SET_ACTION_BAR_HISTORY_ENABLED,
    SET_IS_PINBOARD_UNSAVED,
    SET_VIZ_COLUMN_CONFIG_CHANGE,
    SET_HIGHLIGHT_ANSWER_HEADER,
    SET_SAGE_QUERY_STATE,
    SHOW_SAGE_TOKENS,
    SHOW_SAGE_TOKENS_CONFIG,
    IS_AI_GENERATED,
    SET_IS_AI_ANSWER_UPVOTED,
    SET_AI_ANSWER_DESCRIPTION,
    SET_CHART_DATA_RENDER_ERROR,
    SET_IS_QUERY_VISUALIZER_OPEN,
    SET_ANSWER_ALERT_BANNER,
    SET_IS_ANSWER_HEADER_MENU_DISABLED,
    SET_IS_SAVE_VIEW_DISABLED,
    SET_IS_VERSION_HISTORY_VISIBLE,
    SET_IS_VERSION_HISTORY_INVIEW,
    SET_SHOW_VERSION_HISTORY_SUCCESS_TOAST,
    SET_CUSTOM_ACTIONS,
    SET_ANSWER_ID,
    SET_SPOTIQ_ANALYSIS_STATUS,
    SET_IS_SPOTIQ_ALERT_OPEN,
    SET_SPOTIQ_ANALYSIS_TYPE,
    SET_IS_KPI_FROM_HOME_WATCHLIST,
    AI_ANSWER_FORMULA_INFO,
    AI_ANSWER_REQUEST_ID,
    AI_ANSWER_AMBIGUOUS_PHRASES,
    AI_ANSWER_AMBIGUOUS_TOKENS,
    SET_HAS_OAUTH_FAILURE,
    SET_TABLE_WITH_NO_DATA,
}

export enum AnswerMode {
    ANSWER = 'ANSWER',
    PINBOARD_TILE = 'PINBOARD_TILE',
    PRESENT = 'PRESENT',
    MONITOR = 'MONITOR',
    EXPLORE = 'EXPLORE',
    CONV_ASSIST_ANSWER_EDIT = 'CONV_ASSIST_ANSWER_EDIT',
}

export enum AnswerPageContext {
    UNSAVED_ANSWER = 'UNSAVED_ANSWER',
    SAVED_ANSWER = 'SAVED_ANSWER',
    VIEW_ANSWER = 'VIEW_ANSWER',
    COHORT_ANSWER = 'COHORT_ANSWER',
    PINBOARD_VIZ = 'PINBOARD_VIZ',
    PINBOARD_VIZ_EDIT_MODAL = 'PINBOARD_VIZ_EDIT_MODAL',
    SPOTIQ_VIZ = 'SPOTIQ_VIZ',
    SPOTIQ_VIZ_EDIT_MODAL = 'SPOTIQ_VIZ_EDIT_MODAL',
    EXPLORE = 'EXPLORE',
    PRESENT = 'PRESENT',
    CONV_ASSIST_ANSWER = 'CONV_ASSIST_ANSWER',
}

/**
 * Show global (page-level) alert when the answer is rendered in
 *   a page-level context. E.g., Saved answer, Search data, etc.
 *
 * Show as local (section-level) otherwise when there are multiple answers
 *   are visible on the page. E.g., Liveboard, Conv Assist, etc.
 * - Also, including `PRESENT` in this local alert list so visual is consistent
 *     with the liveboard viz.
 */
export const answerPageContextsToShowGlobalAlertFor = [
    AnswerPageContext.UNSAVED_ANSWER,
    AnswerPageContext.SAVED_ANSWER,
    AnswerPageContext.VIEW_ANSWER,
    AnswerPageContext.COHORT_ANSWER,
    AnswerPageContext.PINBOARD_VIZ_EDIT_MODAL,
    AnswerPageContext.SPOTIQ_VIZ_EDIT_MODAL,
    AnswerPageContext.EXPLORE,
];

export enum PreferredDisplayMode {
    CHART = 'CHART-MODE',
    TABLE = 'TABLE-MODE',
    NO_PREFERENCE = 'NO_PREFERENCE',
    R_ANALYSIS_MODE = 'R_ANALYSIS_MODE',
}

export enum RequestAccessSourceForMixpanel {
    REQUEST_ACCESS_BUTTON = 'Request Access Button',
    EXPLORE = 'Explore',
    CREATE_ALERT = 'Create Alert',
    MANAGE_ALERT = 'Manage Alert ',
    SHOW_UNDERLYING_DATA = 'Show Underlying Data',
    SPOTIQ_ANALYSIS = 'SpotIQ Analysis',
    CONTEXT_MENU = 'Context Menu',
}

export const preferredDisplayModeToDisplayModeMap = {
    [DisplayMode.ChartMode]: PreferredDisplayMode.CHART,
    [DisplayMode.TableMode]: PreferredDisplayMode.TABLE,
    [DisplayMode.HeadlineMode]: PreferredDisplayMode.NO_PREFERENCE,
    [DisplayMode.RAnalysisMode]: PreferredDisplayMode.R_ANALYSIS_MODE,
    [DisplayMode.Undefined]: PreferredDisplayMode.NO_PREFERENCE,
};

export enum AnswerEditPanelComponents {
    CHART_SELECTOR,
    VIZ_EDITOR,
    CUSTOM_R,
    QUERY_DETAILS,
    EXPLORE,
    ACTION_SELECTOR,
    PATH_ANALYZER,
}

const LB_CHART_VIZ_CACHE_EXCLUDED_CHART_TYPES = [ChartType.PivotTable];

export const AnswerColumnOptimisicResKey = 'Answer__updateColumn';
export const AnswerDisplayModeMutation = 'Answer__updateProperties';

export type AnswerColumnRenameParam = {
    variables: {
        columnId: string;
        name: string;
    };
    answerId: string;
    viz: TableViz | ChartViz;
};

interface EntityHeader {
    __typename: string;
    displayName: string;
    guid: string;
}

interface AnswerColumnDataProps {
    __typename: string;
    columnProperties: any;
    version: string;
}

interface AnswerColumnData {
    __typename: string;
    aggregationType: string;
    baseColumnType: string;
    calendarGuid: string;
    columnProps: AnswerColumnDataProps;
    customCalendarType: any;
    customOrder: any[];
    dataType: string;
    format: any;
    formatPattern: string | null;
    formatType: any;
    formulaId: string;
    geoConfig: any;
    id: string;
    isAdditive: boolean;
    isAggregateApplied: boolean;
    isGroupBy: boolean;
    isUserDefinedTitle: boolean;
    legacyColumnFormatProperties: any;
    legacySheetProperties: any;
    name: string;
    referencedColumns: EntityHeader[];
    referencedTables: EntityHeader[];
    showGrowth: boolean;
    timeBucket: string;
    type: string;
}

interface VizColumnData {
    __typename: string;
    column: AnswerColumnData;
    legacyMetricDefinition: any;
}

export type UnsavedChangesData = {
    generationNo: string;
    hasUnsavedChanges: boolean;
    transactionId: string;
    mode: DisplayMode;
    columnData: VizColumnData[];
};

export type SyncData = {
    id: string;
    type: string;
    sourceName: string;
    sourceId: string;
    destType: string;
    connectionId?: string;
    isEditMode?: boolean;
};

export interface AnswerActionDisabledConfig {
    isDisabled: boolean;
    disabledTooltip?: string;
}

export interface contextType {
    context?: AssociatedActionContext;
    enabled?: string | boolean;
}
export interface CustomActionType {
    actionAssociationMap?: Record<string, Record<string, contextType>>;
    availability?: ActionAvailability[];
    context?: AssociatedActionContext;
    detail?: ActionDetail;
    id: string;
    name?: string;
    type: ActionTypes;
    userGroupList?: string[];
}

export interface AnswerPageConfigInterface {
    aiAnswerAmbiguousPhrases?: AiAnswerAmbiguousPhrase[];
    aiAnswerAmbiguousTokens?: AiAnswerAmbiguousToken[];
    fewShotExamples?: AiAnswerFewshotExample[];
    answerId?: string;
    answerSessionId?: string;
    insightSpecId?: string;
    isActionMenuHidden?: boolean;
    isA3Hidden?: boolean;
    isVisualizationPinnerHidden?: boolean;
    uplevelSaveBtn?: boolean;
    hideChooseSources?: boolean;
    embedActionStateMap?: Map<Action, ActionState>;
    isInsertInToSlideEnabled?: boolean;
    isSharingHidden?: boolean;
    isChartSwitcherHidden?: boolean;
    isLeftPanelDisabled?: boolean;
    isLeftPanelHidden?: boolean;
    isLeftPanelCollapsed?: boolean;
    isAnswerPanelHidden?: boolean;
    isSageAssistantHidden?: boolean;
    isCustomRHidden?: boolean;
    isPathAnalyzerHidden?: boolean;
    isChartSelectorHidden?: boolean;
    isActionSelectorHidden?: boolean;
    isChartConfiguratorHidden?: boolean;
    isSwitchToV1Hidden?: boolean;
    showClipboardToast?: boolean;
    tableEditorTabId?: ColumnEditorTab;
    isQueryDetailsHidden?: boolean;
    emptyAnswerState?: EmptyAnswer;
    answerPanelState?: AnswerEditPanelComponents;
    chart?: React.RefObject<Chart>;
    selectedColumnId?: string;
    selectedAxisId?: string;
    referenceSavedState?: Partial<Answer>;
    answerDescriptionClassname?: string;
    headerClassName?: string;
    hasAiGeneratedDescription?: boolean;
    isInsightDataSupported?: boolean;
    vizConfigValidationError?: string;
    isAdhocAnswer?: boolean;
    answerMode?: AnswerMode;
    pageContext?: AnswerPageContext;
    isSavedView?: boolean;
    savedViewGuid?: string;
    // If editing a cohort definition answer, this is the ID of the cohort column.
    cohortColumnId?: string;
    // If editing a cohort definition answer, this is the ID of the cohort definition answer
    cohortAnswerId?: string;
    columnIdToBeRemoved?: string;
    pinboardVizEditContext?: PinboardVizEditContext;
    answerPermissions?: ObjectPermission;
    hiddenActions?: Set<string>;
    disabledActions?: Set<string>;
    visibleActions?: Set<string>;
    embedDisabledReason?: string;
    menuActionsDisabledConfig?: Map<
        Action | string,
        AnswerActionDisabledConfig
    >;
    openShareDialogOnInit?: boolean;
    tableBatchSize?: number;
    tableStartOffset?: number;
    selectedMetric?: { index: number; type: TABS };
    isTableLoading?: boolean;
    shouldEnableFirstPinExperience?: boolean;
    canShowDualIntentPinButton?: boolean;
    customMenuActions?: ActionMenuItem[];
    isCustomActionsHidden?: boolean;
    hiddenAxisMenuActions?: Set<ControlMenuActionTypes>;
    isFilterPanelHidden?: boolean;
    onSaveCB?: (savedAnswerId: string) => void;
    isAnswerTitleDisabled?: boolean;
    isAnswerHeadDisabled?: boolean;
    isEditTitleDisabled?: boolean;
    isAxisContextMenuDisabled?: boolean;
    eurekaSageToggleHidden?: boolean;
    showSearchButtonTour?: boolean;
    isAnswerLoading?: boolean;
    buttonActions?: Set<AnswerAction>;
    actionsRightToMoreButton?: Set<AnswerAction>;
    menuActions?: Set<AnswerAction>;
    actionHandlers?: { [key in AnswerAction]?: any };
    // (ToDo: Need to asses a better place for this PB specific property)
    tileLayoutSize?: DefinedSizes;
    isSearchAssistActive?: boolean;
    showActions?: boolean;
    isPresented?: boolean;
    isActionBarHistoryEnabled?: boolean;
    enableChangeAnalysisContextMenuAction?: boolean;
    isYAxisAttribute?: boolean;
    displayedVizId?: string;
    eagerLoadAnswerTableData?: boolean;
    isPinboardUnsaved?: boolean;
    vizColumnConfigChange?: boolean;
    getVisibleContextMenuActions?: (
        contextMenuInput: ContextMenuInputPoints,
        displayMode: DisplayMode,
        permissions: ObjectPermission,
        actionList: string[],
        sessionService?: SessionService,
    ) => ContextMenuOption[];
    applyOrRemoveCrossFilter?: (
        contextMenuInput: ContextMenuInputPoints,
        action: GenericMenuItemType,
        vizType?: string,
    ) => void;
    highlightAnswerHeader?: boolean;
    refetchAiAnswerWithCache?: () => void;
    sageQueryState?: SageSearchResponse;
    showSageTokens?: boolean;
    showSageTokensConfig?: ShowSageTokensConfig;
    isAiGenerated?: boolean;
    aiAnswerFormulaInfo?: AnswerFormulaInfo[];
    aiAnswerRequestId?: string;
    isAIAnswerUpvoted?: boolean;
    hideSyncActions?: boolean;
    chartDataRenderError?: ChartDataRenderError;
    isQueryVisualizerOpen?: boolean;
    aiAnswerDescription?: string;
    exploreContext?: ExploreContext;
    changeAnalysisContext?: ChangeAnalysisContext;
    answerAlertBanner?: AnswerAlertBanner;
    isAnswerHeaderMenuDisabled?: boolean;
    isSaveViewDisabled?: boolean;
    setDocumentTitle?: (explicitTitle: string) => void;
    isVersionHistoryVisible?: boolean;
    isVersionHistoryInView?: boolean;
    showVersionHistorySuccessToast?: boolean;
    customActions?: CustomActionType[];
    transientAnswerMap?: Map<string, string>;
    onIterativeChangeAnalysis?: (
        columnGuid: string,
        attributeValue: string,
        attributeName: string,
    ) => void;
    showIterativeMenuItem?: boolean;
    spotIQAnalysisStatus?: AnalysisStatus;
    isSpotIQAlertOpen?: boolean;
    spotIQAnalysisType?: string;
    isKpiFromHomeWatchlist?: boolean;
    isChangeAnalysisInView?: boolean;
    formulaInfo?: AnswerFormulaInfo[];
    hasOAuthFailure?: boolean;
    isTrainSageMode?: boolean;
    isTrainSageModeCreate?: boolean;
    columnOverviewConfig?: {
        hidePopularAnswers?: boolean;
    };
    tableWithNoData?: boolean;
}

export enum eureka_AmbiguityType {
    COLUMN_SELECTION,
    KEYWORD_SELECTION,
    UNKNOWN,
    VALUE_SELECTION,
}

interface eureka_RecognizedPhrase {
    phraseType: PhraseType;
    token: RecognizedToken[];
}

export interface AiAnswerAmbiguousPhrase {
    alternativePhrases: [eureka_RecognizedPhrase];
    ambiguityType: eureka_AmbiguityType;
    phraseType: PhraseType;
    token: RecognizedToken[];
}

export interface AiAnswerAmbiguousToken {
    alternativeTokens: RecognizedToken[];
    ambiguityType: eureka_AmbiguityType;
    token: RecognizedToken;
}

export interface AiAnswerFewshotExample {
    chartType: string;
    formulas: AnswerFormulaInfo[];
    id: string;
    mappingId: string;
    nlQuery: string;
    nlQueryConcepts: string[];
    sageQuery: string;
    scope: string;
    sql: string;
    tml: string;
    feedbackType: 'QUERY' | 'FRAGMENT';
}

export interface FixerAnswerData {
    feedbackMode: boolean;
    worksheetId: string;
    fixerAnswerGenNo: number;
    fixerAnswerAcGenNo: number;
    scope: string;
}

export interface OriginalVizParams {
    vizType: DisplayMode;
    vizId: string;
}

export interface PinboardVizEditContext {
    disableUpdate?: boolean;
    isPinboardViz?: boolean;
    originalVizParams?: OriginalVizParams;
    onPinboardModelUpdate?: (
        updatePinboardModelId: string,
        containerId?: string,
    ) => void;
    isHeadlineVizRemoved?: boolean;
}

export interface ExploreContext {
    onPinningSuccessCallback?: (
        pinnedPinboardId: string,
        containerId?: string,
    ) => void;
}

export interface ChangeAnalysisContext {
    onPinningSuccessCallback?: (
        pinnedPinboardId: string,
        containerId?: string,
    ) => void;
}

export const DEFAULT_EMPTY_ANSWER_STATE: EmptyAnswer = {
    emptyAnswerEnum: EmptyAnswerStateEnum.Default,
    searchAssistSourceId: [],
    autoSelectDatasourceInfo: {
        isDatasourceAutoSelected: false,
    },
};

export const getMonitorActionConfig = (
    action: Action,
    embedConfig?: EmbedContextOptions,
    answerPermissions?: ObjectPermission,
    hasUnsavedChanges = false,
    isAttributeInVisualizedSection = false,
    isRequestAccessExpEnabled = false,
    hasOauthFailure = false,
) => {
    const embedDisabledReason = getDisabledActionReason(embedConfig);
    const embedDisabledActions = getDisabledActionsForEmbed(embedConfig);
    const isDisabled =
        embedDisabledActions.has(action) ||
        (!isRequestAccessExpEnabled &&
            answerPermissions.dataSourceAccessLevel === AccessMode.NoAccess);
    const disabledTooltip = embedDisabledActions.has(action)
        ? embedDisabledReason
        : translate('monitor_rule.permission.underlying_data_access');
    if (isDisabled) {
        return {
            isDisabled,
            disabledTooltip,
        };
    }

    if (isAttributeInVisualizedSection) {
        return {
            isDisabled: true,
            disabledTooltip: translate(
                'monitor_rule.answer.attribute_kpi_unsupported',
            ),
        };
    }

    if (hasUnsavedChanges) {
        return {
            isDisabled: true,
            disabledTooltip: translate(
                'monitor_rule.pinboard.reload_page_to_monitor',
            ),
        };
    }

    if (hasOauthFailure) {
        return {
            isDisabled: true,
            disabledTooltip: translate('embrace.oauthMutedAlert.heading'),
        };
    }

    return {
        isDisabled: false,
        disabledTooltip: '',
    };
};

export const findAnswerEditSessionObjFromData = (
    obj: any,
    objLevel: number,
): AnswerEditSession | null => {
    const TYPENAME = '__typename';
    if (
        obj[TYPENAME] === 'AnswerEditSession' &&
        obj.answer &&
        obj.id?.sessionId &&
        obj.answer.visualizations
    ) {
        return obj as AnswerEditSession;
    }
    let answerEditSessionObj: AnswerEditSession;
    if (obj === DIG_UPTO_LEVEL) {
        return answerEditSessionObj;
    }
    Object.values(obj).forEach(prop => {
        if (typeof prop === 'object') {
            answerEditSessionObj =
                answerEditSessionObj ||
                findAnswerEditSessionObjFromData(prop, objLevel + 1);
        }
    });
    return answerEditSessionObj;
};

export const getMenuItemsConfig = (
    pinboardVizEditContext: PinboardVizEditContext,
    embedConfig: EmbedContextOptions,
    answerPermissions: ObjectPermission,
    hasVisualizations: boolean,
    isYAxisAttribute: boolean,
    isRequestAccessExpEnabled = false,
    hasOAuthFailure = false,
): Map<Action | string, AnswerActionDisabledConfig> => {
    const defaultMenuActions = [
        Action.Edit,
        Action.RenameModalTitleDescription,
        Action.Save,
        Action.MakeACopy,
        Action.SaveAsView,
        Action.ShowUnderlyingData,
        Action.AnswerDelete,
        Action.DownloadAsPng,
        Action.DownloadAsCsv,
        Action.DownloadAsPdf,
        Action.DownloadAsXlsx,
        Action.Download,
        Action.ExportTML,
        Action.UpdateTML,
        Action.EditTML,
        Action.TML,
        Action.SpotIQAnalyze,
        Action.CreateMonitor,
        Action.ManageMonitor,
        Action.Explore,
        Action.ShowVersionHistory,
        Action.EnableVersionControl,
        Action.DisableVersionControl,
    ];
    const defaultMenuActionsSet = new Set<string | Action>(defaultMenuActions);
    const menuItemsConfig = new Map<
        Action | string,
        AnswerActionDisabledConfig
    >();
    const embedDisabledActions = getDisabledActionsForEmbed(embedConfig);
    const embedDisabledReason = getDisabledActionReason(embedConfig);
    const disabledActionTooltipMsg = () => {
        if (answerPermissions.objectAccessLevel !== AccessMode.Modify)
            return translate('readonly.tooltip');
        return translate('permission.requestUnderlyingDataAccess');
    };

    defaultMenuActions.forEach((key: Action) => {
        switch (key) {
            case Action.Edit: {
                const isDisabled =
                    pinboardVizEditContext?.disableUpdate ||
                    embedDisabledActions.has(key) ||
                    answerPermissions.objectAccessLevel !== AccessMode.Modify ||
                    answerPermissions.dataSourceAccessLevel ===
                        AccessMode.NoAccess ||
                    !hasVisualizations;
                let disabledTooltip =
                    pinboardVizEditContext?.isPinboardViz &&
                    pinboardVizEditContext?.isHeadlineVizRemoved
                        ? translate('pinnedHeadline.tooltip')
                        : null;
                disabledTooltip =
                    disabledTooltip ??
                    (embedDisabledActions.has(key)
                        ? embedDisabledReason
                        : disabledActionTooltipMsg());
                if (
                    answerPermissions.objectAccessLevel === AccessMode.Modify &&
                    answerPermissions.dataSourceAccessLevel ===
                        AccessMode.Modify &&
                    !embedDisabledActions.has(key) &&
                    !hasVisualizations
                ) {
                    disabledTooltip = translate(
                        'emptyViz.disabledBtnTooltipMsg',
                    );
                }
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.RenameModalTitleDescription: {
                const isDisabled =
                    pinboardVizEditContext?.disableUpdate ||
                    embedDisabledActions.has(key) ||
                    answerPermissions.objectAccessLevel !== AccessMode.Modify ||
                    answerPermissions.dataSourceAccessLevel ===
                        AccessMode.NoAccess ||
                    !hasVisualizations;
                let disabledTooltip =
                    pinboardVizEditContext?.isPinboardViz &&
                    pinboardVizEditContext?.isHeadlineVizRemoved
                        ? translate('pinnedHeadline.tooltip')
                        : null;
                disabledTooltip =
                    disabledTooltip ??
                    (embedDisabledActions.has(key)
                        ? embedDisabledReason
                        : disabledActionTooltipMsg());
                if (
                    answerPermissions.objectAccessLevel === AccessMode.Modify &&
                    answerPermissions.dataSourceAccessLevel ===
                        AccessMode.Modify &&
                    !embedDisabledActions.has(key) &&
                    !hasVisualizations
                ) {
                    disabledTooltip = translate(
                        'emptyViz.disabledBtnTooltipMsg',
                    );
                }
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.Save: {
                const isDisabled =
                    embedDisabledActions.has(key) ||
                    answerPermissions.objectAccessLevel !== AccessMode.Modify;
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('permission.requestEditAccess');
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.MakeACopy: {
                const isDisabled =
                    embedDisabledActions.has(key) ||
                    answerPermissions.dataSourceAccessLevel ===
                        AccessMode.NoAccess;
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('permission.requestDataAccess');
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.SaveAsView: {
                const isDisabled =
                    !canManageWorksheetViewsTables() ||
                    embedDisabledActions.has(key) ||
                    answerPermissions.dataSourceAccessLevel ===
                        AccessMode.NoAccess;
                let disabledTooltip = '';
                if (!canManageWorksheetViewsTables()) {
                    if (isRolesEnabled()) {
                        disabledTooltip = translate(
                            'metadataExplorer.needsCanManageWorksheetTablesViewsPrivileges',
                        );
                    } else {
                        disabledTooltip = translate(
                            'metadataExplorer.needsDataManagementPrivileges',
                        );
                    }
                } else if (embedDisabledActions.has(key)) {
                    disabledTooltip = embedDisabledReason;
                } else {
                    disabledTooltip = translate(
                        'permission.requestUnderlyingDataAccess',
                    );
                }
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.ShowUnderlyingData: {
                const isDisabled =
                    embedDisabledActions.has(key) ||
                    (!isRequestAccessExpEnabled &&
                        answerPermissions.dataSourceAccessLevel ===
                            AccessMode.NoAccess);
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('permission.requestDataAccess');
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.AnswerDelete: {
                const isDisabled =
                    embedDisabledActions.has(key) ||
                    !(
                        (answerPermissions.dataSourceAccessLevel !==
                            AccessMode.NoAccess &&
                            answerPermissions.objectAccessLevel ===
                                AccessMode.Modify) ||
                        (answerPermissions.dataSourceAccessLevel !==
                            AccessMode.ReadOnly &&
                            answerPermissions.objectAccessLevel ===
                                AccessMode.Modify)
                    );
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('permission.requestEditAccess');
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.DownloadAsPng: {
                const isDisabled =
                    !hasDownloadAnswerPrivileges() ||
                    embedDisabledActions.has(key);
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('privilege.requestAccess.tooltip', {
                          privilegeType: translate(
                              'privilegesLabels.DATADOWNLOADING',
                          ),
                      });
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.DownloadAsCsv: {
                const isDisabled =
                    !hasDownloadAnswerPrivileges() ||
                    embedDisabledActions.has(key);
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('privilege.requestAccess.tooltip', {
                          privilegeType: translate(
                              'privilegesLabels.DATADOWNLOADING',
                          ),
                      });
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.DownloadAsPdf: {
                const isDisabled =
                    !hasDownloadAnswerPrivileges() ||
                    embedDisabledActions.has(key);
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('privilege.requestAccess.tooltip', {
                          privilegeType: translate(
                              'privilegesLabels.DATADOWNLOADING',
                          ),
                      });
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.DownloadAsXlsx: {
                const isDisabled =
                    !hasDownloadAnswerPrivileges() ||
                    embedDisabledActions.has(key);
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('privilege.requestAccess.tooltip', {
                          privilegeType: translate(
                              'privilegesLabels.DATADOWNLOADING',
                          ),
                      });
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.Download: {
                const isDisabled =
                    !hasDownloadAnswerPrivileges() ||
                    embedDisabledActions.has(key);
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('privilege.requestAccess.tooltip', {
                          privilegeType: translate(
                              'privilegesLabels.DATADOWNLOADING',
                          ),
                      });
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.TML:
            case Action.ExportTML:
            case Action.UpdateTML:
            case Action.EditTML: {
                const isDisabled =
                    embedDisabledActions.has(key) ||
                    answerPermissions.dataSourceAccessLevel ===
                        AccessMode.NoAccess;
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('permission.requestEditAccess');
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.SpotIQAnalyze: {
                const isDisabled =
                    embedDisabledActions.has(key) ||
                    (!isRequestAccessExpEnabled &&
                        answerPermissions.dataSourceAccessLevel ===
                            AccessMode.NoAccess);
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('permission.requestUnderlyingDataAccess');
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.CreateMonitor:
            case Action.ManageMonitor: {
                menuItemsConfig.set(
                    key,
                    getMonitorActionConfig(
                        key,
                        embedConfig,
                        answerPermissions,
                        false,
                        isYAxisAttribute,
                        isRequestAccessExpEnabled,
                        hasOAuthFailure && key === Action.CreateMonitor,
                    ),
                );
                break;
            }
            case Action.Explore: {
                const isDisabled =
                    embedDisabledActions.has(key) ||
                    (!isRequestAccessExpEnabled &&
                        answerPermissions.dataSourceAccessLevel ===
                            AccessMode.NoAccess);
                const disabledTooltip = embedDisabledActions.has(key)
                    ? embedDisabledReason
                    : translate('permission.requestDataAccess');
                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip,
                });
                break;
            }
            case Action.EnableVersionControl:
            case Action.ShowVersionHistory:
            case Action.DisableVersionControl: {
                const isDisabled =
                    embedDisabledActions.has(key) ||
                    answerPermissions.objectAccessLevel !== AccessMode.Modify;

                menuItemsConfig.set(key, {
                    isDisabled,
                    disabledTooltip: '',
                });
                break;
            }
            default: {
                break;
            }
        }
    });
    const embedDisabledCustomActions = [...embedDisabledActions].filter(
        key => !defaultMenuActionsSet.has(key),
    );
    embedDisabledCustomActions.forEach(key => {
        menuItemsConfig.set(key, {
            isDisabled: true,
            disabledTooltip: embedDisabledReason,
        });
    });
    return menuItemsConfig;
};

export const shouldHideMonitorIconForEmbed = (
    embedConfig: EmbedContextOptions,
) => {
    if (!embedConfig.isEmbedded) {
        return false;
    }
    if (!isMonitorEnabledOnTse()) {
        return true;
    }
    return shouldHideForEmbed(embedConfig, Action.CreateMonitor);
};

export const shouldHideA3 = (
    answerPageConfig: AnswerPageConfigInterface,
    embedConfig: EmbedContextOptions,
): boolean => {
    return (
        answerPageConfig.isA3Hidden ||
        shouldHideForEmbed(embedConfig, Action.SpotIQAnalyze)
    );
};

const shouldHideActionMenu = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return answerPageConfig.isActionMenuHidden;
};

const shouldHideCustomR = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return answerPageConfig.isCustomRHidden;
};

const shouldHidePathAnalyzer = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return answerPageConfig.isPathAnalyzerHidden;
};

const shouldHideSharing = (
    answerPageConfig: AnswerPageConfigInterface,
    embedConfig: EmbedContextOptions,
): boolean =>
    answerPageConfig.isSharingHidden ||
    shouldHideForEmbed(embedConfig, Action.Share);

const shouldHideVisualizationPinner = (
    answerPageConfig: AnswerPageConfigInterface,
    embedConfig: EmbedContextOptions,
): boolean =>
    answerPageConfig.isVisualizationPinnerHidden ||
    shouldHideForEmbed(embedConfig, Action.Pin);

const shouldUplevelSaveBtn = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => answerPageConfig.uplevelSaveBtn;

const shouldHideChooseSources = (
    answerPageConfig: AnswerPageConfigInterface,
    embedConfig: EmbedContextOptions,
): boolean => {
    return (
        answerPageConfig.hideChooseSources ||
        shouldHideForEmbed(embedConfig, Action.ChooseDataSources)
    );
};

const shouldGetEmbedActionStateMap = (
    embedConfig: EmbedContextOptions,
): Map<Action, ActionState> => {
    return getEmbedActionStateMap(embedConfig);
};

const shouldEnableInsertToSlide = (
    embedConfig: EmbedContextOptions,
): boolean => {
    const { embedParams } = embedConfig;
    return embedParams.insertInToSlide;
};

const shouldDisableLeftPanel = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return (
        answerPageConfig.isLeftPanelDisabled ||
        answerPageConfig.answerPermissions?.dataSourceAccessLevel ===
            AccessMode.NoAccess
    );
};

const shouldHideLeftPanel = (
    answerPageConfig: AnswerPageConfigInterface,
    embedConfig: EmbedContextOptions,
): boolean => {
    const { embedParams } = embedConfig;
    return embedParams.dataSourceMode === DataSourceVisualMode.Hidden;
};

const shouldOpenQVModal = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return answerPageConfig.isQueryVisualizerOpen;
};

const shouldCollapseLeftPanel = (
    answerPageConfig: AnswerPageConfigInterface,
    embedConfig: EmbedContextOptions,
): boolean => {
    const { embedParams } = embedConfig;
    return (
        embedParams.dataSourceMode === DataSourceVisualMode.Collapsed ||
        answerPageConfig.isLeftPanelCollapsed
    );
};

const shouldHideAnswerPanel = (embedConfig: EmbedContextOptions): boolean => {
    const { embedParams } = embedConfig;
    return embedParams.hideResult === true;
};

const shouldHideChartSwitcher = (
    answerPageConfig: AnswerPageConfigInterface,
    embedConfig: EmbedContextOptions,
): boolean => {
    return (
        answerPageConfig.isChartSwitcherHidden ||
        shouldHideForEmbed(embedConfig, Action.AnswerChartSwitcher)
    );
};

const shouldHideChartSelector = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return answerPageConfig.isChartSelectorHidden;
};

const shouldHideActionSelector = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return answerPageConfig.isActionSelectorHidden;
};

const shouldHideQueryDetails = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return answerPageConfig.isQueryDetailsHidden;
};

const shouldHideChartConfigurator = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return answerPageConfig.isChartConfiguratorHidden;
};

const shouldHideSwitchToV1 = (
    answerPageConfig: AnswerPageConfigInterface,
    embedConfig: EmbedContextOptions,
): boolean => {
    return answerPageConfig.isSwitchToV1Hidden || embedConfig.isEmbedded;
};

const getEmptyAnswerPageState = (
    answerPageConfig: AnswerPageConfigInterface,
): EmptyAnswer => {
    return answerPageConfig.emptyAnswerState;
};

const getIsAdhocAnswer = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return answerPageConfig.isAdhocAnswer;
};

const getIsSavedView = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return answerPageConfig.isSavedView;
};

const shouldOpenShareDialogOnInit = (
    answerPageConfig: AnswerPageConfigInterface,
): boolean => {
    return answerPageConfig.openShareDialogOnInit;
};

export const getDisabledActionsForAnswer = (
    embedConfig: EmbedContextOptions,
) => {
    const disabledActionItems = getDisabledActionsForEmbed(embedConfig);
    if (!hasDownloadAnswerPrivileges()) {
        disabledActionItems.add(Action.DownloadAsCsv);
        disabledActionItems.add(Action.DownloadAsPdf);
        disabledActionItems.add(Action.DownloadAsXlsx);
        disabledActionItems.add(Action.Download);
    }
    return disabledActionItems;
};

export const hasAccessPermission = (permission: ObjectPermission): boolean => {
    return (
        permission.dataSourceAccessLevel !== AccessMode.NoAccess &&
        (permission.objectAccessLevel === AccessMode.Modify ||
            permission.objectAccessLevel === AccessMode.ReadOnly)
    );
};

export const hasEditTMLPermission = (permission: ObjectPermission): boolean => {
    const flag =
        permission.dataSourceAccessLevel !== AccessMode.NoAccess &&
        permission.objectAccessLevel === AccessMode.Modify;
    return flag;
};

export const getHiddenActionItems = (
    answerPageConfig: AnswerPageConfigInterface,
    embedConfig: EmbedContextOptions,
) => {
    let hiddenActionItems = embedConfig.isEmbedded
        ? getHiddenActions(embedConfig)
        : answerPageConfig.hiddenActions;
    if (!hiddenActionItems) {
        hiddenActionItems = new Set();
    }

    if (!hasA3AnalysisPrivileges()) {
        hiddenActionItems.add(Action.SpotIQAnalyze);
    }

    if (!hasAccessPermission(answerPageConfig.answerPermissions)) {
        hiddenActionItems.add(Action.ExportTML);
    } else {
        hiddenActionItems.delete(Action.ExportTML);
    }

    if (!hasEditTMLPermission(answerPageConfig.answerPermissions)) {
        hiddenActionItems.add(Action.EditTML);
        hiddenActionItems.add(Action.UpdateTML);
    } else {
        hiddenActionItems.delete(Action.EditTML);
        hiddenActionItems.delete(Action.UpdateTML);
    }

    return hiddenActionItems;
};

export const createAnswerPageConfig = (
    answerPageConfig: AnswerPageConfigInterface,
    embedConfig: EmbedContextOptions,
    pinboardVizEditContext: PinboardVizEditContext,
    hasVisualizations: boolean,
): AnswerPageConfigInterface => {
    return {
        isA3Hidden:
            shouldHideA3(answerPageConfig, embedConfig) ||
            !hasA3AnalysisPrivileges(),
        isActionMenuHidden: shouldHideActionMenu(answerPageConfig),
        isSharingHidden: shouldHideSharing(answerPageConfig, embedConfig),
        isCustomRHidden: shouldHideCustomR(answerPageConfig),
        isPathAnalyzerHidden: shouldHidePathAnalyzer(answerPageConfig),
        isVisualizationPinnerHidden: shouldHideVisualizationPinner(
            answerPageConfig,
            embedConfig,
        ),
        uplevelSaveBtn: shouldUplevelSaveBtn(answerPageConfig),
        hideChooseSources: shouldHideChooseSources(
            answerPageConfig,
            embedConfig,
        ),
        embedActionStateMap: shouldGetEmbedActionStateMap(embedConfig),
        isInsertInToSlideEnabled: shouldEnableInsertToSlide(embedConfig),
        isChartSwitcherHidden: shouldHideChartSwitcher(
            answerPageConfig,
            embedConfig,
        ),
        isLeftPanelDisabled: shouldDisableLeftPanel(answerPageConfig),
        isLeftPanelHidden: shouldHideLeftPanel(answerPageConfig, embedConfig),
        isLeftPanelCollapsed: shouldCollapseLeftPanel(
            answerPageConfig,
            embedConfig,
        ),
        isAnswerPanelHidden: shouldHideAnswerPanel(embedConfig),
        isChartSelectorHidden: shouldHideChartSelector(answerPageConfig),
        isActionSelectorHidden: shouldHideActionSelector(answerPageConfig),
        isQueryDetailsHidden: shouldHideQueryDetails(answerPageConfig),
        isChartConfiguratorHidden: shouldHideChartConfigurator(
            answerPageConfig,
        ),
        isSwitchToV1Hidden: shouldHideSwitchToV1(answerPageConfig, embedConfig),
        emptyAnswerState: getEmptyAnswerPageState(answerPageConfig),
        isAdhocAnswer: getIsAdhocAnswer(answerPageConfig),
        isSavedView: getIsSavedView(answerPageConfig),
        answerPermissions: answerPageConfig.answerPermissions,
        hiddenActions: getHiddenActionItems(answerPageConfig, embedConfig),
        visibleActions: embedConfig.isEmbedded
            ? getVisibleActions(embedConfig)
            : answerPageConfig.visibleActions,
        disabledActions: getDisabledActionsForAnswer(embedConfig),
        embedDisabledReason: getDisabledActionReason(embedConfig),
        menuActionsDisabledConfig: getMenuItemsConfig(
            pinboardVizEditContext,
            embedConfig,
            answerPageConfig.answerPermissions,
            hasVisualizations,
            answerPageConfig.isYAxisAttribute,
            false,
            answerPageConfig.hasOAuthFailure,
        ),
        openShareDialogOnInit: shouldOpenShareDialogOnInit(answerPageConfig),
        isQueryVisualizerOpen: shouldOpenQVModal(answerPageConfig),
    };
};

export function setVisualizationLoading(
    client: ApolloClient<object>,
    answerSessionId: string,
    loadingState: LoadingState,
) {
    const answerEditSession = {
        __typename: 'AnswerEditSession',
        id: {
            sessionId: answerSessionId,
        },
    };
    const answerSession: AnswerEditSession = client.cache.readQuery({
        id: client.cache.identify(answerEditSession),
        query: gql`
            {
                answer {
                    id
                }
            }
        `,
    });
    client.cache.writeFragment({
        id: client.cache.identify(answerSession.answer),
        fragment: gql`
            fragment isLoading on Answer {
                visualizationsLoading @client
            }
        `,
        data: {
            visualizationsLoading: loadingState,
        },
    });
}

export function setNextGenNoShouldReplaceCurrent(
    client: ApolloClient<object>,
    answerSessionId: string,
    nextGenNoShouldReplaceCurrent = false,
) {
    const answerEditSession = {
        __typename: 'AnswerEditSession',
        id: {
            sessionId: answerSessionId,
        },
    };
    const answerSession: AnswerEditSession = client.cache.readQuery({
        id: client.cache.identify(answerEditSession),
        query: gql`
            {
                answer {
                    id
                }
            }
        `,
    });
    client.cache.writeFragment({
        id: client.cache.identify(answerSession.answer),
        fragment: gql`
            fragment nextGenNoShouldReplaceCurrent on Answer {
                nextGenNoShouldReplaceCurrent @client
            }
        `,
        data: {
            nextGenNoShouldReplaceCurrent,
        },
    });
}

export const getClientStateRequest = (
    visualizations: Visualization[] = [],
    answerSessionId: string,
) => {
    return visualizations.reduce(
        (
            variables: any,
            cachedViz: ChartViz | TableViz | HeadlineViz | RAnalysisViz,
            index: number,
        ) => {
            /* eslint-disable no-param-reassign */
            if (index > 0) {
                variables[`session_${index}`] = {
                    sessionId: answerSessionId,
                };
            }
            variables[`vizId_${index}`] = cachedViz.id;
            variables[`clientState_${index}`] =
                (cachedViz as any)?.vizProp || {};

            /* eslint-disable-next-line no-underscore-dangle */
            if (cachedViz.__typename === 'ChartViz') {
                // this variables needed for chart lock mutation
                variables.isLocked = cachedViz.config.isLocked;
                variables.answerSessionId = answerSessionId;
            }
            return variables;
        },
        {},
    );
};

const getCachedVisualization = (
    client: ApolloClient<object>,
    answerSessionId: string,
) => {
    const answerEditSession = {
        __typename: 'AnswerEditSession',
        id: {
            sessionId: answerSessionId,
        },
    };
    const result: AnswerEditSession = client.cache.readQuery({
        id: client.cache.identify(answerEditSession),
        query: gql`
            {
                answer {
                    id
                    visualizations {
                        id
                    }
                }
            }
        `,
    });
    return result?.answer?.visualizations;
};
const getCachedVisualizationByAnswerId = (
    client: ApolloClient<object>,
    answerId: string,
) => {
    const answer = {
        __typename: 'Answer',
        id: answerId,
    };
    const result: Answer = client.cache.readQuery({
        id: client.cache.identify(answer),
        query: gql`
            {
                id
                visualizations {
                    id
                }
            }
        `,
    });
    return result?.visualizations;
};

export const deleteVizDataCache = (
    client: ApolloClient<object>,
    answerSessionId: string,
) => {
    const visualizations = getCachedVisualization(client, answerSessionId);
    if (visualizations?.length) {
        visualizations.forEach(
            (viz: ChartViz | TableViz | HeadlineViz | RAnalysisViz) => {
                client.cache.evict({
                    id: client.cache.identify({
                        id: viz.id,
                        /* eslint-disable-next-line no-underscore-dangle */
                        __typename: viz.__typename,
                    }),
                    broadcast: false,
                    fieldName: 'data',
                });
            },
        );
    }
};

export const deleteChartVizFromCache = (
    client: ApolloClient<object>,
    vizId?: string,
) => {
    client.cache.evict({
        id: client.cache.identify({
            id: vizId,
            __typename: 'ChartViz',
        }),
        broadcast: false,
    });
};

export const getChartVizWithDataFromApolloCache = (
    client: ApolloClient<object>,
    vizId: string,
): ChartViz => {
    return client.cache.readQuery({
        id: client.cache.identify({
            id: vizId,
            __typename: 'ChartViz',
        }),
        query: gql`
            {
                id
                data
            }
        `,
    });
};

const writeChartVizWithDataToApolloCache = (
    client: ApolloClient<object>,
    vizId: string,
    chartViz: ChartViz,
) => {
    client.cache.writeFragment({
        id: client.cache.identify({
            id: vizId,
            __typename: 'ChartViz',
        }),
        fragment: gql`
            fragment viz on ChartViz {
                clientState
                columns
                config
                customVisualProps
                data
                id
                sortInfo
                sortOrder
                topInfo
                vizProp
            }
        `,
        data: _.cloneDeep(chartViz),
        broadcast: false,
    });
};

export const checkAndWriteChartVizFromLocalCacheToApolloCache = (
    client: ApolloClient<object>,
    answerSessionId: string,
    vizId: string,
) => {
    const chartViz = readFromLBChartVizCache(answerSessionId)?.chartViz;
    // if local cache doesnt have data, return
    if (!chartViz) return;
    const chartVizFromApolloCache = getChartVizWithDataFromApolloCache(
        client,
        vizId,
    );
    // if apollo cache already has data, return
    if (chartVizFromApolloCache) return;

    writeChartVizWithDataToApolloCache(client, vizId, chartViz);

    // evicting from chartviz cache with a settimeout to delay
    // the eviction so that apollo cache write is completed
    // and the watchers doesnt get empty state until the write is completed
    setTimeout(() => {
        evictLBChartVizCache(answerSessionId);
    });
};

export function deleteTableVizCache(
    client: ApolloClient<object>,
    result: any,
): void {
    const answerEditSession = findAnswerEditSessionObjFromData(result.data, 0);
    if (!answerEditSession) {
        return null;
    }
    const visualizations = getCachedVisualizationByAnswerId(
        client,
        answerEditSession?.answer?.id,
    );
    if (visualizations?.length) {
        visualizations.forEach(
            (viz: ChartViz | TableViz | HeadlineViz | RAnalysisViz) => {
                /* eslint-disable-next-line no-underscore-dangle */
                if (viz.__typename === 'ChartViz') {
                    return;
                }
                client.cache.evict({
                    id: client.cache.identify({
                        id: viz.id,
                        /* eslint-disable-next-line no-underscore-dangle */
                        __typename: viz.__typename,
                    }),
                    broadcast: false,
                    fieldName: 'data',
                });
            },
        );
    }
    return null;
}
export const evictAnswer = (
    client: ApolloClient<object>,
    answerId: string,
    shouldCollectGarbage = true,
) => {
    client.cache.evict({
        id: client.cache.identify({
            id: answerId,
            __typename: 'Answer',
        }),
        broadcast: false,
    });
    if (shouldCollectGarbage) {
        client.cache.gc();
    }
};

export function getQueryDeadlineMs() {
    return envFlags.getValue(FLAGS?.queryDeadlineMs?.name)
        ? envFlags.getValue(FLAGS?.queryDeadlineMs?.name)
        : 0;
}

export function refetchTableData(client: ApolloClient<object>, result: any) {
    const answerEditSession = findAnswerEditSessionObjFromData(result.data, 0);
    if (!answerEditSession) {
        return [];
    }
    const session = getSession(
        client,
        answerEditSession.id?.sessionId,
        'BachSessionId',
        bachSessionKeyQuery,
    );
    deleteVizDataCache(client, answerEditSession.id?.sessionId);
    const dataPaginationParams = {
        isClientPaginated: true,
        offset: 0,
        size:
            envFlags.getValue(FLAGS?.dataBatchSize?.name) ||
            blinkConstants.DEFAULT_DATA_BATCH_SIZE,
    };
    const deadline = getQueryDeadlineMs();
    return [
        {
            query: GetTableWithDataDocument,
            variables: {
                session,
                deadline,
                dataPaginationParams,
            },
        },
    ];
}

export const deleteVizDataCacheByAnswerId = (
    client: ApolloClient<object>,
    answerId: string,
    broadcast = false,
) => {
    const visualizations = getCachedVisualizationByAnswerId(client, answerId);
    if (visualizations?.length) {
        visualizations.forEach(
            (viz: ChartViz | TableViz | HeadlineViz | RAnalysisViz) => {
                client.cache.evict({
                    id: client.cache.identify({
                        id: viz.id,
                        /* eslint-disable-next-line no-underscore-dangle */
                        __typename: viz.__typename,
                    }),
                    broadcast,
                    fieldName: 'data',
                });
            },
        );
    }
};

export function refetchVizData(
    client: ApolloClient<object>,
    result: any,
): Array<string | PureQueryOptions> {
    const answerEditSession = findAnswerEditSessionObjFromData(result.data, 0);
    if (!answerEditSession) {
        return [];
    }
    deleteVizDataCacheByAnswerId(client, answerEditSession?.answer?.id);
    return [];
}

export function getUserSelectionPoints(
    contextMenuPoints: ContextMenuInputPoints,
) {
    // Regardless of what attributes are selected or not, always add all the
    // attributes in that row as filters, to make sure that we are drilling only the
    // data represented by that row.
    let { selectedPoints } = contextMenuPoints;
    if (!selectedPoints.find(pt => pt === contextMenuPoints.clickedPoint)) {
        selectedPoints = [contextMenuPoints.clickedPoint, ...selectedPoints];
    }
    const selectedPointsTuple: ContextMenuInputValue[][] = [];
    selectedPoints.forEach((selectedPoint: any) => {
        selectedPointsTuple.push([
            ...selectedPoint.selectedAttributes,
            ...selectedPoint.deselectedAttributes,
        ]);
    });
    const dataValues = selectedPointsTuple[0].map(
        (contextMenuPoint: ContextMenuInputValue) => {
            return {
                columnId: contextMenuPoint.column.id,
                dataValue: [] as any,
            };
        },
    );

    selectedPointsTuple.forEach(selectedPointTupleEntry => {
        const tuple: any = [];
        selectedPointTupleEntry.forEach(
            (contextMenuPoint: ContextMenuInputValue, idx: number) => {
                let val: any = {};
                if (
                    isDateColumn(contextMenuPoint.column) ||
                    isTimeColumn(contextMenuPoint.column)
                ) {
                    // The isNumber function checks if a value is a number, ignoring number values that are formatted as strings.
                    // The isNaN function checks values formatted as strings to determine if it is number or not.
                    if (!Number.isNaN(Number(contextMenuPoint.value))) {
                        const parsedValue = parseInt(
                            (contextMenuPoint.value as unknown) as string,
                            10,
                        );
                        val = {
                            epochRange: {
                                startEpoch: parsedValue,
                            },
                        };
                    } else if (
                        contextMenuPoint.value &&
                        contextMenuPoint.value.v
                    ) {
                        val = {
                            epochRange: {
                                startEpoch: getStartEpoch(
                                    contextMenuPoint.value,
                                ),
                                ...(contextMenuPoint.value.v?.e && {
                                    endEpoch: getEndEpoch(
                                        contextMenuPoint.value,
                                    ),
                                }),
                            },
                        };
                    } else {
                        val = {
                            value:
                                contextMenuPoint.value ??
                                translate('NULL_VALUE_PLACEHOLDER_LABEL', {
                                    skipInterpolation: true,
                                }),
                        };
                    }
                } else {
                    // here we handling a case when date is num column and for that we just need startEpoch
                    const result =
                        getStartEpoch(contextMenuPoint.value) ||
                        contextMenuPoint.value;
                    val = {
                        value:
                            result === null
                                ? translate('NULL_VALUE_PLACEHOLDER_LABEL', {
                                      skipInterpolation: true,
                                  })
                                : `${result}`,
                    };
                }
                tuple.push(val);
            },
        );
        let duplicate = false;
        if (dataValues.length > 0) {
            // Check when dataValues is present
            // Can be a case when drill down is done having only measures on search
            // No additional filter is sent from blink in such case
            dataValues[0].dataValue.forEach((value: any, idx: number) => {
                let isMatch = true;
                tuple.forEach((val: any, index: number) => {
                    if (!_.isEqual(dataValues[index].dataValue[idx], val)) {
                        isMatch = false;
                    }
                });
                if (isMatch) {
                    duplicate = true;
                }
            });
        }
        if (!duplicate) {
            tuple.forEach((val: any, idx: number) => {
                dataValues[idx].dataValue.push(val);
            });
        }
    });

    // Reduce the duplicate columnId for multiple attribute values in pivot table.
    // Sample example: [{columnId: '1', value; 'ship}, {columnId: '1', value; 'air}] to
    // [{columnId: '1', dataValue: [{ value: 'ship' }, { value: 'air' }]}]
    const dataValuesUniqueKeys = _.reduce(
        dataValues,
        (result, value) => {
            const columnObj = _.find(
                result,
                v => v.columnId === value.columnId,
            );
            if (columnObj) {
                columnObj.dataValue.push(...value.dataValue);
            } else {
                result.push(value);
            }
            return result;
        },
        [],
    );

    return dataValuesUniqueKeys;
}

export function getUserSelectionMeasureValues(
    contextMenuPoints: ContextMenuInputPoints,
) {
    // The flow of execution for this function is the same as
    // getUserSelectionPoints, but for selected measures instead of selected
    // attributes, and no de-duplication of points.
    let { selectedPoints } = contextMenuPoints;
    if (
        !selectedPoints.find(pt =>
            _.isEqual(pt, contextMenuPoints.clickedPoint),
        )
    ) {
        selectedPoints = [contextMenuPoints.clickedPoint, ...selectedPoints];
    }
    const selectedPointsTuple: ContextMenuInputValue[][] = [];
    selectedPoints.forEach((selectedPoint: any) => {
        selectedPointsTuple.push([
            ...selectedPoint.selectedMeasures,
            ...selectedPoint.deselectedMeasures,
        ]);
    });
    const dataValues = selectedPointsTuple[0].map(
        (contextMenuPoint: ContextMenuInputValue) => {
            return {
                columnId: contextMenuPoint.column.id,
                dataValue: [] as any,
            };
        },
    );

    selectedPointsTuple.forEach(selectedPointTupleEntry => {
        const tuple: any = [];
        selectedPointTupleEntry.forEach(
            (contextMenuPoint: ContextMenuInputValue, idx: number) => {
                let val: any = {};
                const result = contextMenuPoint.value;
                val = {
                    value:
                        result === null
                            ? translate('NULL_VALUE_PLACEHOLDER_LABEL', {
                                  skipInterpolation: true,
                              })
                            : `${result}`,
                };
                tuple.push(val);
            },
        );
        tuple.forEach((val: any, idx: number) => {
            dataValues[idx].dataValue.push(val);
        });
    });

    const dataValuesUniqueKeys = _.reduce(
        dataValues,
        (result, value) => {
            const columnObj = _.find(
                result,
                v => v.columnId === value.columnId,
            );
            if (columnObj) {
                columnObj.dataValue.push(...value.dataValue);
            } else {
                result.push(value);
            }
            return result;
        },
        [],
    );

    return dataValuesUniqueKeys;
}

export const getOptimisticVisualization = (
    cacheData: Record<string, any>,
    vizRefs: Reference[],
    answerViz: ChartViz | TableViz,
    columnData: {
        columnId: string;
        name: string;
    },
): Visualization[] => {
    const { columnId, name } = columnData;
    return vizRefs?.map((viz: Reference) => {
        /* eslint-disable-next-line no-underscore-dangle */
        const cachedViz: ChartViz | TableViz = cacheData[viz.__ref];
        if (answerViz.id !== cachedViz.id) {
            /* eslint-disable-next-line no-underscore-dangle */
            return (cacheData[viz.__ref] as unknown) as Visualization;
        }

        const updateIndex = cachedViz.columns.findIndex(
            vizColumn => vizColumn.column.id === columnId,
        );
        const updatedVizColumn: VizColumn = {
            ...cachedViz.columns[updateIndex],
            column: {
                ...cachedViz.columns[updateIndex].column,
                name,
                isUserDefinedTitle: true,
            },
        };
        /* eslint-disable-next-line no-underscore-dangle */
        if (cachedViz.__typename === 'ChartViz' && cachedViz.config) {
            const updatedConfig = updateAxisConfigColumns(cachedViz, {
                id: columnId,
                name,
                isUserDefinedTitle: true,
            });
            const customChartManifest = (cachedViz.config
                .customChartManifest as unknown) as Reference;

            const chartConfig = {
                ...cachedViz.config,
                customChartManifest:
                    /* eslint-disable-next-line no-underscore-dangle */
                    customChartManifest && cacheData[customChartManifest.__ref],
                axisConfig: updatedConfig,
            };

            return {
                ...cachedViz,
                columns: Object.assign([], cachedViz.columns, {
                    [updateIndex]: updatedVizColumn,
                }),
                config: chartConfig,
            };
        }
        return {
            ...cachedViz,
            columns: Object.assign([], cachedViz.columns, {
                [updateIndex]: updatedVizColumn,
            }),
        };
    });
};

export const getOptimisticResponseForColumn = (
    client: ApolloClient<Record<string, any>>,
    answerSessionId: string,
    answerId: string,
    answerViz: ChartViz | TableViz,
    columnData: {
        columnId: string;
        name: string;
    },
) => {
    const cacheData = client.cache.extract();
    const cachedAnswer =
        cacheData[
            client.cache.identify({
                /* eslint-disable-next-line no-underscore-dangle */
                __typename: 'Answer',
                id: answerId,
            })
        ];
    const vizRefs = (cachedAnswer?.visualizations as Reference[]) || [];

    return {
        id: {
            sessionId: answerSessionId,
        },
        answer: {
            ...(cachedAnswer as Answer),
            visualizations: getOptimisticVisualization(
                cacheData,
                vizRefs,
                answerViz,
                columnData,
            ),
        },
    };
};

export const evictRefAnswerOfAnswerEditSession = (
    client: ApolloClient<object>,
    answerSessionId: string,
) => {
    const answerEditSession = {
        __typename: 'AnswerEditSession',
        id: {
            sessionId: answerSessionId,
        },
    };
    client.cache.evict({
        id: client.cache.identify(answerEditSession),
        fieldName: 'refAnswer',
        broadcast: false,
    });
};

export const evictAnswerEditSession = (
    client: ApolloClient<object>,
    answerSessionId: string,
) => {
    const answerEditSession = {
        __typename: 'AnswerEditSession',
        id: {
            sessionId: answerSessionId,
        },
    };
    client.cache.evict({
        id: client.cache.identify(answerEditSession),
        broadcast: false,
    });
    evictLBChartVizCache(answerSessionId);
};

export const evictImpactedContainers = (
    client: ApolloClient<object>,
    pinboardSessionId: string,
) => {
    const pinboardEditSession = {
        __typename: 'PinboardEditSession',
        id: {
            sessionId: pinboardSessionId,
        },
    };
    client.cache.evict({
        id: client.cache.identify(pinboardEditSession),
        fieldName: 'impactedContextBookId',
        broadcast: false,
    });
};

export const getOptimisitResponseForDisplayMutation = (
    client: ApolloClient<Record<string, any>>,
    answerSessionId: string,
    answerId: string,
    displayMode: DisplayMode,
) => {
    const cacheData = client.cache.extract();
    const cachedAnswer =
        cacheData[
            client.cache.identify({
                /* eslint-disable-next-line no-underscore-dangle */
                __typename: 'Answer',
                id: answerId,
            })
        ];
    const vizRefs = (cachedAnswer?.visualizations as Reference[]) || [];
    return {
        id: {
            sessionId: answerSessionId,
        },
        answer: {
            ...(cachedAnswer as Answer),
            displayMode,
            visualizations: vizRefs.map(viz => {
                /* eslint-disable-next-line no-underscore-dangle */
                return cacheData[viz.__ref];
            }),
        },
    };
};

export const getSelectedColumnsFromQueryData = (
    data: GetSelectedColumnsQuery,
) => {
    let selectedColumns: Set<string> = new Set();
    const allSelectedColumns = data?.getAnswer.answer.selectedColumns;
    const allSelectedFormulas = data?.getAnswer?.answer?.selectedFormulas || [];
    if (allSelectedColumns) {
        const allSelectedColumnGuids = allSelectedColumns.map(
            column => column.guid,
        );
        selectedColumns = new Set(allSelectedColumnGuids);
    }
    if (allSelectedFormulas) {
        allSelectedFormulas.forEach(formula =>
            selectedColumns.add(formula.formulaId),
        );
    }
    return selectedColumns;
};

export const getOptimisiticResponseForAnswerTitleDescription = (
    client: ApolloClient<Record<string, any>>,
    answerSessionId: string,
    answerId: string,
    key: 'name' | 'description',
    value: string,
) => {
    const cacheData = client.cache.extract();
    const cachedAnswer =
        cacheData[
            client.cache.identify({
                /* eslint-disable-next-line no-underscore-dangle */
                __typename: 'Answer',
                id: answerId,
            })
        ];
    const vizRefs = (cachedAnswer?.visualizations as Reference[]) || [];
    return {
        id: {
            sessionId: answerSessionId,
        },
        answer: {
            ...(cachedAnswer as Answer),
            [key]: value,
            visualizations: vizRefs.map(viz => {
                /* eslint-disable-next-line no-underscore-dangle */
                return cacheData[viz.__ref];
            }),
        },
    };
};

export const hasHtmlTags = (html: string) => /<\/?[a-z][\s\S]*>/i.test(html);

/**
 * Extracts plain text from an HTML string.
 *
 * @param {string} html - The input HTML string.
 * @returns {string} - The plain text extracted from the HTML string.
 */
export const getTextFromHTMLTags = (html: string, defaultText = '') => {
    if (!html || html.trim() === '') {
        return '';
    }

    if (!hasHtmlTags(html)) {
        return html;
    }

    const parser = new DOMParser();
    const elm = parser.parseFromString(html, 'text/html');
    return elm.body.textContent || defaultText;
};

export const seedQuestionAlertSubject = new Subject();
export const clearUndoStackSubject = new Subject();

/*
 * Given any possible type of visualization, we will extract and return the columns within it. By
 * default it only includes visualized columns, but it can also return all columns if desired.
 */
export const getColumnsFromViz = (
    viz?: FinalVisualization,
    onlyIncludeVisualizedCols = true,
): AnswerColumn[] => {
    // eslint-disable-next-line no-underscore-dangle
    if (!viz?.__typename) return [];

    // eslint-disable-next-line no-underscore-dangle
    const typename = viz.__typename;

    // For headline and R-analysis viz, we don't need to do any special handling
    if (
        !onlyIncludeVisualizedCols &&
        typename !== 'HeadlineViz' &&
        typename !== 'RAnalysisViz'
    ) {
        return viz.columns.map(col => col.column);
    }

    if (typename === 'ChartViz') {
        return getVisualizedColumns(viz);
    }

    if (typename === 'HeadlineViz') {
        return getHeadlineColumns(viz);
    }
    if (typename === 'TableViz' || typename === 'RAnalysisViz') {
        return getColumns(viz as TableViz);
    }

    logger.error('Unrecognized visualization type', typename, viz);
    return [];
};

export const getStepNumberForLoader = (
    vizQueryStatus: VisualizationQueryStatus,
) => {
    const queryStatusToStepMap = {
        [VisualizationQueryStatus.WaitingForConnection]:
            CircularStepLoaderSteps.ONE,
        [VisualizationQueryStatus.ExecutingQuery]: CircularStepLoaderSteps.TWO,
        [VisualizationQueryStatus.ProcessingResults]:
            CircularStepLoaderSteps.THREE,

        [VisualizationQueryStatus.Completed]: CircularStepLoaderSteps.THREE,
    };
    return queryStatusToStepMap[vizQueryStatus] ?? 1;
};

/**
 * Decides whether to display the loading text or not
 * Loading text will only gets displayed when the tile dimension meets a certain breakpoint
 * @param vizLoadingStatusConfig
 * @returns boolean
 */
export const canShowLoaderText = (
    vizLoadingStatusConfig: VizLoadingStatusConfig,
) => {
    if (_.isUndefined(vizLoadingStatusConfig)) {
        return false;
    }

    const {
        loadingStatus,
        vizContainerSize,
        isWaitingTimeElapsed,
    } = vizLoadingStatusConfig;
    const { height, width } = vizContainerSize;
    const {
        minRequiredHeight,
        minRequiredWidth,
    } = breakpointsForStepLoader.loaderText;

    return (
        loadingStatus?.status &&
        isWaitingTimeElapsed &&
        height >= minRequiredHeight &&
        width >= minRequiredWidth
    );
};

/**
 * Returns the size of the loader to be displayed based on the tile dimensions
 * @param vizLoadingStatusConfig
 * @returns CircularLoaderSize
 */
export const getLoaderSize = (
    vizLoadingStatusConfig: VizLoadingStatusConfig,
) => {
    if (!vizLoadingStatusConfig) {
        return null;
    }

    const { vizContainerSize } = vizLoadingStatusConfig;
    const { height } = vizContainerSize;

    const {
        minHeightForSmallLoader,
        minHeightForNormalLoader,
    } = breakpointsForStepLoader.loaderSize;

    if (
        height >= minHeightForSmallLoader &&
        height < minHeightForNormalLoader
    ) {
        return CircularLoaderSize.SMALL;
    }

    if (height >= minHeightForNormalLoader) {
        return CircularLoaderSize.NORMAL;
    }

    return null;
};

/**
 * Returns the status of the viz query, if the circular step loader
 * is being displayed.
 * @param vizLoadingStatusConfig
 * @returns string representing the query status
 */
export const getVizQueryStatus = (
    vizLoadingStatusConfig: VizLoadingStatusConfig,
) => {
    if (canShowLoaderText(vizLoadingStatusConfig)) {
        const { status } = vizLoadingStatusConfig.loadingStatus;
        return translate(`embrace.liveboardQueryStatus.${status}`);
    }
    return '';
};

/**
 * Returns the props required for the circular step loader
 */
export const getCircularLoaderProps = (
    vizLoadingStatusConfig: VizLoadingStatusConfig,
) => {
    if (vizLoadingStatusConfig?.loadingStatus) {
        return {
            activeState: getStepNumberForLoader(
                vizLoadingStatusConfig.loadingStatus.status,
            ),
            loaderSize: getLoaderSize(vizLoadingStatusConfig),
        };
    }
    return {};
};

export const isLBChartVizCachingSupported = (chartViz?: ChartViz) => {
    return (
        chartViz &&
        !LB_CHART_VIZ_CACHE_EXCLUDED_CHART_TYPES.includes(
            getChartType(chartViz),
        )
    );
};

export interface AnswerFormulaInfo {
    name?: string;
    expression?: string;
}

// Calculates the number of lines occupied by the content of an HTML element specified by the query selector,
// compares it against the provided limit, and truncates the content if it exceeds the limit. Returns true if
// the content is truncated, false otherwise
export const checkAndTruncateIfExceedsLineLimit = (
    query: string,
    lines: number,
) => {
    const textContainer = document.querySelector(query) as HTMLElement;
    const elementProps = window.getComputedStyle(textContainer);
    const actualLineHeight = elementProps
        .getPropertyValue('line-height')
        .slice(0, -2);
    const clientHeight = textContainer.clientHeight;
    const calculatedNumberOfLines = Math.ceil(
        clientHeight / parseInt(actualLineHeight, 10),
    );
    textContainer.style['-webkit-line-clamp'] = lines;
    return calculatedNumberOfLines > lines;
};

export const OPEN_SHARE_DIALOG_URL_FLAG = 'openShareDialog';
export const insufficientPermissionErrorCodes = ['13059', '13090', '13091'];
export const invalidAnswerErrorCodes = [
    '13003',
    'BAD_USER_INPUT',
    'INTERNAL_SERVER_ERROR',
];

export const isPopoverOpen = () => {
    const popovers = document.querySelectorAll('[class*="positionable"]');
    for (let i = 0; i < popovers.length; i++) {
        if (
            popovers[i].matches('[class*="_in"]') &&
            !popovers[i].matches('[class*="_out"]')
        )
            return true;
    }
    return false;
};

// Function to set a session storage item with an expiry time
// Here ttl is Time To Live
export function setSessionStorageWithExpiry(
    key: string,
    value: string,
    ttl: number,
) {
    const now = new Date();
    const expiryTime = now.getTime() + ttl;

    // Store the value along with the expiry time
    const item = {
        value,
        expiry: expiryTime,
    };

    sessionStorage.setItem(key, JSON.stringify(item));
}

// Function to get a session storage item and check for expiry
export function getSessionStorageItem(key: string) {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) {
        return null;
    }

    let item;
    try {
        item = JSON.parse(itemStr);
    } catch {
        return null;
    }

    const now = new Date().getTime();

    // Check if the item has a valid expiry field
    if (!item.expiry || now > item.expiry) {
        sessionStorage.removeItem(key);
        return null;
    }

    return item.value;
}
