const ofModule = (s: string) => `authenticated-app-view-module__${s}`;

export const AUTHENTICATED_APP_VIEW_BLINK_ID = 'AUTHENTICATED_APP_VIEW_BLINK';
export const AUTHENTICATED_APP_VIEW_PAGE_CONTENT_ID =
    'AUTHENTICATED_APP_VIEW_PAGE_CONTENT';

export const AUTHENTICATED_APP_VIEW_BLINK_CLASSES = {
    'force-overflow-auto': ofModule('force-overflow-auto'),
};

export const AUTHENTICATED_APP_VIEW_PAGE_CONTENT_CLASSES = {
    'force-overflow-auto': ofModule('force-overflow-auto'),
};
