import {
    PrivilegedRouteMap,
    PrivilegedRouteObject,
} from '@thoughtspot/blink-context';
import { IconID } from '@thoughtspot/radiant-react/widgets/icon';
import React from 'react';
import { lazyWithPreload as lazy } from 'react-lazy-with-preload';
import { Outlet } from 'react-router-dom';
import { BlinkV2MetricsContextStates } from '/@services/system/blink-v2-metrics-context-states';
import { AUTHENTICATED_CORE_ROUTES, CORE_ROUTES } from './core-routes';
import { INSIGHTS_ROUTES } from './insights-routes';
import { PrivilegeList } from './privilege-list';

export enum Applications {
    INSIGHTS = 'insights',
    DATA = 'data',
    TEAMS_ADMIN = 'teams',
    ADMIN = 'admin',
    DEVELOP = 'develop',
    CORE = 'core',
    ANALYST_STUDIO = 'AnalystStudio',
}

export type Application = Omit<PrivilegedRouteObject, 'handle'> & {
    appName?: Applications;
    handle: string;
    homePath?: string;
    description?: string;
    icon?: IconID;
    shouldRedirectToV1?: boolean;
    titleIconId?: IconID;
};

const DataModelPage = lazy(() => import('../pages/data-management/index'));
const SchemaViewerPage = lazy(() => import('../pages/schema-viewer/index'));
const SpotAppExportPage = lazy(() => import('../pages/spotapp-export/index'));

const WorksheetActions = lazy(() =>
    import('../pages/worksheet-actions/worksheet-actions'),
);

const TrainSagePage = lazy(() => import('../pages/sage/train-sage-page'));
const WorksheetV2 = lazy(() => import('../components/worksheet-v2'));

export const DATA_ROUTES: PrivilegedRouteMap = {
    TrainSagePage: {
        path: '/data/sage/train',
        element: <TrainSagePage />,
        pageContext: BlinkV2MetricsContextStates.TRAIN_SAGE,
    },
    TmlExportPage: {
        path: '/data/spotapp-export',
        element: <SpotAppExportPage />,
        disableTopNav: true,
        pageContext: BlinkV2MetricsContextStates.DATA_MANAGEMENT,
    },
    DataModelPage: {
        path: '/data/*',
        element: <DataModelPage />,
        pageContext: BlinkV2MetricsContextStates.DATA_MANAGEMENT,
    },
    WorksheetAssociation: {
        path: '/worksheetActions/:worksheetId',
        element: <WorksheetActions />,
        pageContext: BlinkV2MetricsContextStates.WORKSHEET_ACTIONS,
    },
    SchemaViewer: {
        path: '/schema-viewer/table',
        element: <SchemaViewerPage />,
        pageContext: BlinkV2MetricsContextStates.DATA_MANAGEMENT,
    },
    WorksheetV2: {
        path: '/worksheet/*',
        element: <WorksheetV2 />,
        pageContext: BlinkV2MetricsContextStates.WORKSHEET,
    },
};

const EverywherePage = lazy(() =>
    import('../pages/embed-dev-portal/everywhere'),
);
const EverywhereStandalonePage = lazy(() =>
    import('../pages/embed-dev-portal/everywhere-standalone'),
);

export const DEVELOP_ROUTES: PrivilegedRouteMap = {
    EverywhereStandalonePage: {
        path: '/everywhere/*',
        element: <EverywhereStandalonePage />,
        disableTopNav: true,
        pageContext: BlinkV2MetricsContextStates.EVERYWHERE_PAGE,
    },
    EverywherePage: {
        path: '/develop/*',
        element: <EverywherePage />,
        pageContext: BlinkV2MetricsContextStates.EVERYWHERE_PAGE,
    },
};

const TeamsPage = lazy(() => import('../pages/teams/teams-page'));

const PurchasePage = lazy(() =>
    import('../components/purchase/purchase.container'),
);

export const TEAMS_ROUTES: PrivilegedRouteMap = {
    TeamsPage: {
        path: '/teams/*',
        element: <TeamsPage />,
        pageContext: BlinkV2MetricsContextStates.TEAMS,
    },
    Purchase: {
        path: '/purchase',
        element: <PurchasePage />,
        pageContext: BlinkV2MetricsContextStates.PURCHASE,
        disableTopNav: true,
    },
};

const AdminPage = lazy(() => import('../pages/admin/admin-page'));

export const ADMIN_ROUTES: PrivilegedRouteMap = {
    AdminPage: {
        path: '/admin',
        element: <AdminPage />,
        pageContext: BlinkV2MetricsContextStates.ADMIN_PAGE,
    },
};

export const ANALYST_STUDIO_ROUTES: PrivilegedRouteMap = {};

export const ROUTES = {
    ...ADMIN_ROUTES,
    ...CORE_ROUTES,
    ...DATA_ROUTES,
    ...DEVELOP_ROUTES,
    ...INSIGHTS_ROUTES,
    ...TEAMS_ROUTES,
    ...ANALYST_STUDIO_ROUTES,
};

export const ROUTE_IDS: any = Object.keys(ROUTES).reduce((acc, key) => {
    acc[key] = key;
    return acc;
}, {});

export const ApplicationList: { [key in Applications]?: Application } = {
    [Applications.INSIGHTS]: {
        children: INSIGHTS_ROUTES,
        description: 'gnav.appSwitcher.description.THOUGHTSPOT_ONE',
        element: <Outlet />,
        handle: Applications.INSIGHTS,
        homePath: '/insights/home',
        icon: IconID.MAGNIFYING_GLASS,
        id: Applications.INSIGHTS,
        title: 'gnav.appSwitcher.THOUGHTSPOT_ONE',
        privileges: [],
        pageContext: BlinkV2MetricsContextStates.DEFAULT_PAGE,
    },
    [Applications.DATA]: {
        children: DATA_ROUTES,
        description: 'gnav.appSwitcher.description.DATA',
        element: <Outlet />,
        handle: Applications.DATA,
        homePath: '/data',
        icon: IconID.SAVE_WORKSHEET,
        id: Applications.DATA,
        title: 'gnav.appSwitcher.DATA',
        privileges: [PrivilegeList.CanAccessDataTab],
        shouldRedirectToV1: true,
        pageContext: BlinkV2MetricsContextStates.DATA_MANAGEMENT,
    },
    [Applications.ANALYST_STUDIO]: {
        children: ANALYST_STUDIO_ROUTES,
        description: 'gnav.appSwitcher.description.ANALYST_STUDIO',
        element: <Outlet />,
        handle: Applications.ANALYST_STUDIO,
        homePath: '',
        icon: IconID.ANALYST_STUDIO,
        id: Applications.ANALYST_STUDIO,
        title: 'gnav.appSwitcher.ANALYST_STUDIO',
        privileges: [PrivilegeList.CanAccessAnalystStudio],
        pageContext: BlinkV2MetricsContextStates.ANALYST_STUDIO,
        titleIconId: IconID.OPEN_IN_NEW_TAB,
    },
    [Applications.DEVELOP]: {
        children: DEVELOP_ROUTES,
        description: 'gnav.appSwitcher.description.DEVELOPER',
        element: <Outlet />,
        handle: Applications.DEVELOP,
        homePath: '/develop',
        icon: IconID.CODE,
        id: Applications.DEVELOP,
        title: 'gnav.appSwitcher.DEVELOPER',
        privileges: [PrivilegeList.HasDeveloperAccess],
        pageContext: BlinkV2MetricsContextStates.EMBED_PAGE,
    },
    [Applications.ADMIN]: {
        children: ADMIN_ROUTES,
        description: 'gnav.appSwitcher.description.ADMIN',
        element: <Outlet />,
        handle: Applications.ADMIN,
        homePath: '/admin',
        icon: IconID.COMMUNITY,
        id: Applications.ADMIN,
        title: 'gnav.appSwitcher.ADMIN',
        privileges: [PrivilegeList.HasAdminAccess],
        shouldRedirectToV1: true,
        pageContext: BlinkV2MetricsContextStates.ADMIN_PAGE,
    },
    [Applications.TEAMS_ADMIN]: {
        children: TEAMS_ROUTES,
        description: 'gnav.appSwitcher.description.ADMIN',
        element: <Outlet />,
        handle: Applications.TEAMS_ADMIN,
        homePath: '/teams',
        icon: IconID.COMMUNITY,
        id: Applications.TEAMS_ADMIN,
        title: 'gnav.appSwitcher.TEAMS_ADMIN',
        privileges: [PrivilegeList.HasTeamsAccess],
        pageContext: BlinkV2MetricsContextStates.TEAMS,
    },
    [Applications.CORE]: {
        children: AUTHENTICATED_CORE_ROUTES,
        element: <Outlet />,
        handle: Applications.CORE,
        homePath: '',
        id: Applications.CORE,
        privileges: [],
        pageContext: BlinkV2MetricsContextStates.DEFAULT_PAGE,
        title: 'gnav.appSwitcher.CORE',
    },
};
