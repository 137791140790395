import { useEmbedContext } from '@thoughtspot/blink-context';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useMatches } from 'react-router';
import { useAppContext } from '/@contexts/global-app-context';
import { UserState } from '../services/system/config-service/session-service';
import { CORE_ROUTES } from './core-routes';
import { INSIGHTS_ROUTE_IDS } from './insights-routes';
import { Applications } from './routes';

export const NavActions: React.FC = () => {
    const { navService, sessionService, systemConfigService } = useAppContext();
    const { isEmbedded } = useEmbedContext();
    const matches = useMatches();
    const currentAppRoute = matches.find(match => match.handle);
    const isLoggedIn = sessionService.isLoggedIn();

    useEffect(() => {
        // Exit out if navService isn't initialized yet.
        if (!navService || !navService.isInitialized) {
            return null;
        }
        if (!isLoggedIn && navService.shouldShowDeepLinkPage()) {
            if (
                !matches.some(match =>
                    match.pathname.includes(CORE_ROUTES.Deeplink.path),
                )
            ) {
                navService.goToDeepLinkPage();
            }
            return null;
        }
        return null;
    }, [navService, currentAppRoute, isLoggedIn]);

    useEffect(() => {
        // Exit out if navService isn't initialized yet.
        if (!navService || !navService.isInitialized) {
            return null;
        }
        if (!isLoggedIn) {
            return null;
        }
        const isUserExpired =
            sessionService.getUserState() === UserState.EXPIRED;
        if (isUserExpired) {
            navService.goToPurchase();
            return null;
        }
        const requiresOnboarding = !sessionService.hasSeenOnboarding();
        const shouldShowOnboarding =
            requiresOnboarding &&
            sessionService.isOnboardingEnabled() &&
            !matches.some(
                match =>
                    match.id === INSIGHTS_ROUTE_IDS.SavedAnswer ||
                    match.id === INSIGHTS_ROUTE_IDS.Pinboard ||
                    match.id === INSIGHTS_ROUTE_IDS.Answer,
            ) &&
            !systemConfigService.isEmbeddedUseCase() &&
            !isEmbedded;
        if (shouldShowOnboarding && currentAppRoute?.id !== Applications.CORE) {
            navService.goToOnBoarding();
        }
        return null;
    }, [isLoggedIn, navService]);

    return null;
};
