import { EmbedContext, EmbedContextOptions } from '@thoughtspot/blink-context';
import { Vertical } from '@thoughtspot/radiant-react/widgets/layout';
import cx from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useAppContext } from '/@contexts/global-app-context';
import {
    EventType,
    getHiddenHomeLeftNavItems,
    getHiddenHomepageModules,
    getReorderedHomepageModules,
    getRuntimeFilterParams,
    getRuntimeParameterParams,
    useEmbedEventsRootHandler,
    useLayoutChangeNotifier,
} from '/@utils/embed.util';
import { EmbedParams, getEmbedQueryParams } from '/@utils/embed-base.util';
import {
    createEventSubscriber,
    destroy,
    dispatchEvent,
    init,
} from '/@utils/event-bridge/event-bridge';
import { Footer } from '../footer/footer';
import styles from './embed.module.scss';

/**
 * Event types for which the same payload can be dispatched repeatedly
 */
const nonMemoizedEventTypes = new Set([
    EventType.CustomAction,
    EventType.EmbedIframeCenter,
    EventType.DialogOpen,
    EventType.DialogClose,
    EventType.Save,
    EventType.Download,
    EventType.DownloadAsPdf,
    EventType.DownloadAsCsv,
    EventType.DownloadAsXlsx,
    EventType.Pin,
    EventType.SpotIQAnalyze,
    EventType.Share,
    EventType.ExportTML,
    EventType.SaveAsView,
    EventType.AnswerDelete,
    EventType.Alert,
    EventType.LiveboardInfo,
    EventType.SchedulesList,
    EventType.Present,
    EventType.Edit,
    EventType.Cancel,
    EventType.Schedule,
    EventType.UpdateTML,
    EventType.EditTML,
    EventType.CopyLink,
    EventType.ShowUnderlyingData,
    EventType.Explore,
    EventType.CreateMonitor,
    EventType.ManageMonitor,
    EventType.MakeACopy,
    EventType.Remove,
    EventType.VizPointClick,
    EventType.VizPointDoubleClick,
    EventType.VizPointRightClick,
    EventType.GET_DATA_CLICK,
    EventType.Data,
    EventType.InsertInToSlide,
    EventType.Error,
    EventType.OnBeforeGetVizDataIntercept,
]);

export const EmbedContainer: React.FC<{
    component: React.ReactElement;
    doNotWrapInContainer?: boolean;
}> = ({ component, doNotWrapInContainer = false }) => {
    const queryParams = getEmbedQueryParams();
    const runtimeFilter = getRuntimeFilterParams();
    const runtimeParameter = getRuntimeParameterParams();
    const hiddenHomeLeftNavItems = getHiddenHomeLeftNavItems();
    const hiddenHomepageModules = getHiddenHomepageModules();
    const reorderedHomepageModules = getReorderedHomepageModules();

    // Maintain embed params in a state, so that it can be updated based on any of the embed events.
    const [embedParams, updateEmbedParams] = useState<EmbedParams>(queryParams);
    const [runtimeFilterParams, updateRuntimeFilterParams] = useState<
        EmbedParams
    >(runtimeFilter);
    const [embedParameterParams, updateEmbedParameterParams] = useState<
        EmbedParams
    >(runtimeParameter);
    const { sessionService, customStylingService } = useAppContext();
    const useEmbedEventSubscription = createEventSubscriber();
    const dispatchEmbedEvent = dispatchEvent(nonMemoizedEventTypes);
    const isPoweredFooterHidden =
        sessionService.isPoweredFooterHiddenForFullEmbed() ||
        sessionService.isPoweredFooterHiddenForVizEmbed();
    const isLiveboardV2Enabled = sessionService.isPinboardV2Enabled();

    const isFullheightDisabled = sessionService.isFullHeightDisabled();

    // On changes in layout, send the updated height to parent frame.
    const ref = useRef<HTMLDivElement>(null);
    const { updateHeight } = useLayoutChangeNotifier(
        dispatchEmbedEvent,
        doNotWrapInContainer,
        isPoweredFooterHidden,
        isFullheightDisabled,
    );
    const resizeTimeout = useRef(null);

    const onWindowResize = () => {
        if (ref.current && isLiveboardV2Enabled) {
            ref.current.setAttribute('style', 'max-height:0px');
            const embedHeight = ref.current.children[0]?.scrollHeight;
            ref.current.removeAttribute('style');
            updateHeight(embedHeight);
        }
    };

    const debounceWindowResize = () => {
        clearTimeout(resizeTimeout.current);
        resizeTimeout.current = setTimeout(() => {
            onWindowResize();
        }, 700);
    };

    useEffect(() => {
        window.addEventListener('resize', debounceWindowResize);
        return () => window.removeEventListener('resize', debounceWindowResize);
    });

    const embedConfig: EmbedContextOptions = {
        isEmbedded: true,
        isPoweredFooterHidden,
        useEmbedEventSubscription,
        dispatchEmbedEvent,
        embedParams,
        updateEmbedParams,
        runtimeFilterParams,
        updateRuntimeFilterParams,
        embedParameterParams,
        updateEmbedParameterParams,
        hiddenHomeLeftNavItems,
        onLayoutChange: () => {
            if (ref.current) {
                ref.current.setAttribute('style', 'max-height:0px');
                const embedHeight = ref.current.children[0]?.scrollHeight;
                ref.current.removeAttribute('style');
                updateHeight(embedHeight);
            }
        },
        hiddenHomepageModules,
        reorderedHomepageModules,
    };

    // Handle the embed events at the root level.
    useEmbedEventsRootHandler(embedConfig);

    useEffect(() => {
        init();
        return () => destroy();
    }, []);
    const footerText = customStylingService.getConfig()?.getFooterText() || '';
    const childParams = { ...queryParams };
    const wrapperClassName = cx(
        styles.tsEmbedWrapper,
        embedParams?.isFullHeightPinboard && !isFullheightDisabled ? styles.fullheight : null,
    );

    const containerClassName = cx(
        styles.tsEmbedContainer,
        embedParams?.isFullHeightPinboard && !isFullheightDisabled ? styles.fullheight : null,
    );

    const child = doNotWrapInContainer ? (
        <div
            ref={ref}
            data-testid="test-component"
            className={wrapperClassName}
            data-embed-wrapper="true"
        >
            {React.cloneElement(component, childParams)}
        </div>
    ) : (
        <div
            ref={ref}
            data-testid="test-component"
            className={containerClassName}
        >
            {/* Pass in query params as props to the embedded component */}
            {React.cloneElement(component, childParams)}
            {!isPoweredFooterHidden && <Footer footerText={footerText} />}
        </div>
    );

    return (
        <EmbedContext.Provider value={embedConfig}>
            {child}
        </EmbedContext.Provider>
    );
};

export default EmbedContainer;
