import { LoadingIndicator } from '@thoughtspot/radiant-react/widgets/loading-indicator';
import { SelectOption } from '@thoughtspot/radiant-react/widgets/select/base-select';
import React, { useState } from 'react';
import { useAppContext } from '/@contexts/global-app-context';
import {
    useGetUserAccessibleOrgsQuery,
    useSwitchOrgMutation,
} from '/@services/session/session-service';
import { reload } from '/@utils/browser.util';
import { NonEmbedAccessSwitchOrg } from './non-embed-access-switch-org';

export const NonEmbedAccessSwitchOrgContainer = () => {
    const { sessionService } = useAppContext();

    const {
        data: orgsList,
        loading: orgsListLoading,
        error: orgsListError,
    } = useGetUserAccessibleOrgsQuery();
    const [selectedOrgId, setSelectedOrgId] = useState<string>('');

    const handleOrgChange = (data: SelectOption) => {
        setSelectedOrgId(data.id);
    };

    const onSuccessSwitchOrg = (isSwitched: boolean) => {
        if (isSwitched) {
            reload();
        }
    };

    const [switchOrg] = useSwitchOrgMutation(
        onSuccessSwitchOrg,
        err => {
            // console.log(err);
        },
        selectedOrgId,
    );

    return (
        <LoadingIndicator.Global hideLoader={!orgsListLoading}>
            {!orgsListLoading && (
                <NonEmbedAccessSwitchOrg
                    isEnableSelectiveUserAccessRestriction={sessionService.isEnableSelectiveUserAccessRestriction()}
                    selectedOrgId={selectedOrgId}
                    orgList={orgsListError ? [] : orgsList?.Data.orgs ?? []}
                    handleOrgChange={handleOrgChange}
                    switchOrgCb={switchOrg}
                />
            )}
        </LoadingIndicator.Global>
    );
};
