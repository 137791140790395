import {
    BLINK_FLAGS,
    containsV1inPath,
    envFlags,
    FLAGS,
    getFlagsFromSimpleJSON,
    getQueryParamsAndQueryString,
    IEnvFlags,
    IFlag,
    IFlagMap,
    initEnvFlags,
} from '@thoughtspot/env-flags';
import { create as createLogger } from '@thoughtspot/logger';

const FLAGS_LISTING_COLUMN_PADDING = 30;

interface IBlinkEnvFlags extends IEnvFlags {
    isOverridden: (flagName: string) => boolean;
    BLINK_FLAGS: typeof FLAGS;
    help: () => void;
}

const generateFlagsHelpText = (flagMap: IFlagMap) => {
    const getFlagPadding = (flag: IFlag) =>
        ' '.repeat(Math.abs(FLAGS_LISTING_COLUMN_PADDING - flag.name.length));
    const getFlagEmptySpace = (flag: IFlag) => ' '.repeat(flag.name.length);
    let helpString = 'Available flags:\n\n';
    helpString += 'Flag                            Description\n';
    Object.values(flagMap).forEach(flag => {
        if (flag.isListed) {
            helpString +=
                `\n${flag.name} ${getFlagPadding(flag)} ` +
                `${flag.description} \n` +
                `${getFlagEmptySpace(flag)} ${getFlagPadding(flag)} ` +
                `(default: ${flag.defaultValue})`;
        }
    });

    helpString += '\n\nFlag can be overridden using url query parameter.';

    return helpString;
};

export const flags: IBlinkEnvFlags = {
    getValue: envFlags.getValue,
    setValue: envFlags.setValue,
    resetValue: envFlags.resetValue,
    isOverridden: flagName => {
        if (!Object.prototype.hasOwnProperty.call(FLAGS, flagName)) {
            return undefined;
        }
        return FLAGS[flagName].overideValue !== undefined;
    },
    BLINK_FLAGS: FLAGS,
    help: () => {
        const helpString = generateFlagsHelpText(FLAGS);
        const logger = createLogger('flags');
        logger.warn(helpString);
    },
};

export function initFlags() {
    // Get blink.env and convert to flags
    const blinkEnvFlags = getFlagsFromSimpleJSON((window as any).blink?.env);

    // Merge all flags together
    const mergedFlags = {
        ...BLINK_FLAGS,
        ...blinkEnvFlags,
    };

    // Call Init from the env-flags package with blink specific flags.
    // This will set the right defaults as well.
    initEnvFlags(mergedFlags);

    const queryParams = getQueryParamsAndQueryString().queryParams;
    const queryFlags = getFlagsFromSimpleJSON(queryParams, true);

    // Call init with queryFlags, init is called again here, since we have to override
    // the existing flags, so all Flags should have already been init'ed.
    initEnvFlags(queryFlags);

    (window as any).flags = flags;
}

/**
 * Overrides blink flags
 */
export function overrideFlags(json: {
    [key: string]: string | boolean | any[];
}): void {
    initEnvFlags(getFlagsFromSimpleJSON(json, true));
}

export { containsV1inPath, FLAGS, getQueryParamsAndQueryString };
