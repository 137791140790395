import { blinkConstants } from '@thoughtspot/blink-constants';
import { BLINK_FLAGS, envFlags } from '@thoughtspot/env-flags';
import {
    FEATURE_FLAG_NAMES,
    isFeatureEnabled,
} from '@thoughtspot/feature-service';
import { jsonConstants } from '@thoughtspot/json-constants';
import {
    addQueryParamsToUrlTemplate,
    cleanupQueryParamsTemplateFromUrl,
    QueryParams,
} from '@thoughtspot/mfe-utilities';
import { getCurrentOrgId, isOrgUiEnabled } from '@thoughtspot/session-service';
import { isPublicUserEnabled } from '@thoughtspot/system-config-service';
import _ from 'lodash';
import { EmbedUrls, ListType } from '/@services/generated/graphql-types';
import { EmbedUrlsEnum, EventType } from '/@utils/embed.util';
import { dispatchNonMemoEvent } from '/@utils/event-bridge/event-bridge';

export const EverywherePropKeys = {
    visualSDKComponent: 'VisualSDKComponent',
    visualSDKDataSourceGUIDs: 'VisualSDKDataSourceGUIDs',
    hostAppURL: 'HostAppURL',
    onPublicInstance: 'OnPublicInstance',
};

const isOrgPerCustomEmbedUrlEnabled = () => {
    return isFeatureEnabled(
        FEATURE_FLAG_NAMES.ORG_PER_CUSTOM_EMBED_URL_FLAG_KEY,
        {
            urlParamValue: envFlags.getValue(
                BLINK_FLAGS.orgPerCustomEmbedUrl?.name,
            ),
        },
    );
};

const getQueryParamsForEmbedUrl = () => {
    const queryParams: QueryParams = {};
    if (isOrgUiEnabled() && isOrgPerCustomEmbedUrlEnabled()) {
        queryParams[
            jsonConstants.ORG_OVERRIDE_QUERY_PARAM_KEY
        ] = getCurrentOrgId().toString();
    }
    return queryParams;
};

export function addEverywhereEventProps(eventProps: {
    [key: string]: any;
}): void {
    const eventProperties = eventProps;
    const EverywhereConstants = blinkConstants.everyWhere;
    const { search, hash } = window?.location;
    const searchParams = new URLSearchParams(search);
    const hasEmbedAppFlag =
        searchParams.get(EverywhereConstants.embedApp) === 'true';
    const hostAppUrl = searchParams.get(EverywhereConstants.hostAppUrl);
    const isEmbedPath = hash.includes('/embed/');
    const isPinboardPath = hash.includes('/viz/');
    const isAnswerPath =
        hash.includes('/answer') || hash.includes('/saved-answer');
    const sdkVersion = searchParams.get(EverywhereConstants.sdkVersion);
    let sdkComponent = null;

    if (isEmbedPath) {
        if (isPinboardPath) {
            const [pinboardId, vizId] = hash
                .split('/viz/')[1]
                .split('?')[0]
                .replace(/\/+$/, '')
                .split('/');
            if (pinboardId) {
                sdkComponent = vizId
                    ? EverywhereConstants.visualisation
                    : EverywhereConstants.pinboard;
            }
        } else if (isAnswerPath) {
            sdkComponent = EverywhereConstants.search;
            eventProperties[
                EverywherePropKeys.visualSDKDataSourceGUIDs
            ] = searchParams.get(EverywhereConstants.dataSources);
        }
    } else if (hasEmbedAppFlag) {
        sdkComponent = EverywhereConstants.fullApp;
    }

    if (sdkVersion) {
        eventProperties[EverywhereConstants.sdkVersion] = sdkVersion;
    }

    eventProperties[EverywherePropKeys.visualSDKComponent] = sdkComponent;
    if (hostAppUrl) {
        eventProperties[EverywherePropKeys.hostAppURL] = hostAppUrl;
    }
    eventProperties[
        EverywherePropKeys.onPublicInstance
    ] = isPublicUserEnabled();
}

export function getEmbeddedCopyUrl(
    objectType: string,
    objectId: string,
    isSpotIQInsight: boolean,
    pinboardVizId: string,
    embedUrls: EmbedUrls,
): string {
    if (_.isNil(embedUrls) || _.isEmpty(embedUrls)) return '';

    let embeddedCopyUrl = '';
    const isSavedAnswer = objectType === ListType.QuestionAnswerBook;
    const isPinboard = objectType === ListType.PinboardAnswerBook;
    if (isSavedAnswer && embedUrls[EmbedUrlsEnum.ANSWER_URL]) {
        embeddedCopyUrl = embedUrls[EmbedUrlsEnum.ANSWER_URL].replace(
            '{object-id}',
            objectId,
        );
    } else if (isPinboard) {
        if (isSpotIQInsight && embedUrls[EmbedUrlsEnum.INSIGHT_URL]) {
            embeddedCopyUrl = embedUrls[EmbedUrlsEnum.INSIGHT_URL].replace(
                '{object-id}',
                objectId,
            );
        } else if (pinboardVizId && embedUrls[EmbedUrlsEnum.PINBOARD_VIZ_URL]) {
            embeddedCopyUrl = embedUrls[EmbedUrlsEnum.PINBOARD_VIZ_URL]
                .replace('{object-id}', objectId)
                .replace('{sub-object-id}', pinboardVizId);
        } else if (embedUrls[EmbedUrlsEnum.PINBOARD_URL]) {
            embeddedCopyUrl = embedUrls[EmbedUrlsEnum.PINBOARD_URL].replace(
                '{object-id}',
                objectId,
            );
        }
    }

    embeddedCopyUrl = addQueryParamsToUrlTemplate(
        embeddedCopyUrl,
        getQueryParamsForEmbedUrl(),
    );
    return cleanupQueryParamsTemplateFromUrl(embeddedCopyUrl);
}

export const notifyAuthInit = (
    userGUID: string,
    mixpanelToken: string,
    isPublicUser: boolean,
) => {
    dispatchNonMemoEvent(EventType.AuthInit, {
        userGUID,
        mixpanelToken,
        isPublicUser,
    });
};

export function getCustomUrl(url: string, data: any) {
    const URL_DELIMITER = '#';
    const isPinboards: boolean = url.includes('pinboards');
    const isPinboard: boolean = url.includes('pinboard');
    const isSavedAnswer: boolean = url.includes('saved-answer');
    const isInsight: boolean = url.includes('insight');
    const isInsights: boolean = url.includes('insights');
    let customUrl = '';
    if (!isPinboards && isPinboard) {
        const embedIds = url.split('pinboard').slice(1);
        const [objectId, vizId = ''] = embedIds;
        customUrl = data.PINBOARD_URL.replace(
            '{object-id}',
            objectId.slice(1),
        ).replace('{sub-object-id}', vizId);
    } else if (isSavedAnswer) {
        const objectId = url.split('saved-answer')[1].slice(1);
        customUrl = data.ANSWER_URL.replace('{object-id}', objectId);
    } else if (isInsight && !isInsights) {
        const objectId = url.split('insight')[1].slice(1);
        customUrl = data.INSIGHT_URL.replace('{object-id}', objectId);
    } else {
        // generic url if none of the above matches the criteria
        customUrl = `${
            data.GENERIC_URL.split(URL_DELIMITER)[0]
        }${URL_DELIMITER}${url.split('#')[1]}`;
    }

    customUrl = addQueryParamsToUrlTemplate(
        customUrl,
        getQueryParamsForEmbedUrl(),
    );
    return cleanupQueryParamsTemplateFromUrl(customUrl);
}

export function hasCustomUrls(embedUrls: any) {
    return !!embedUrls;
}
