import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import {
    FEATURE_FLAG_NAMES,
    isFeatureEnabled,
    OBSERVABILITY_CONFIG,
} from '@thoughtspot/feature-service';
import {
    getLogger,
    getMeter,
    getTracer,
    initTelemetry,
    Logger,
    Meter,
    ValueType,
} from '@thoughtspot/observability';
import { getSentryAnonymizedDSN } from '@thoughtspot/session-service';
import { useEffect, useRef } from 'react';
import { OTLP_CONFIG } from './observability-service.const';

const LOGGER: Logger = getLogger('observability-service');

export const initializeReplay = async (sampleRate: number) => {
    try {
        const { initReplayService, startAnonymizedReplay } = await import(
            '@thoughtspot/observability'
        );
        const anonymizedDSN = getSentryAnonymizedDSN();
        if (!anonymizedDSN) {
            LOGGER.warn('Sentry DSN is empty; skipping replay initialization');
        }
        await initReplayService(anonymizedDSN);
        if (Math.random() < sampleRate) {
            LOGGER.info('Sampling replay');
            await startAnonymizedReplay();
        }
    } catch (err) {
        LOGGER.error(err);
    }
};

let globalMeter: Meter = null;

type Records = {
    renderCounter?: any;
    firstRenderTimer?: any;
    renderTimer?: any;
};

export const useMetrics = (componentName: string) => {
    const records = useRef<Records>({});
    const time = useRef<DOMHighResTimeStamp>(performance.now());
    const isFirstRender = useRef<boolean>(true);

    useEffect(() => {
        if (!globalMeter) {
            try {
                const newMeter = getMeter(componentName);
                globalMeter = newMeter;
            } catch (e) {
                // Telemetry hasn't been initialized so no=op
                // Can't return here since we need to mark firstRender
            }
        }
        if (isFirstRender.current) {
            isFirstRender.current = false;
            if (!globalMeter) {
                // Telemetry hasn't been initialized so don't do anything
                return;
            }
            const elapsedTime = performance.now() - time.current;
            // First-renders are heavier than re-renders so count them separately
            records.current.firstRenderTimer = globalMeter.createGauge(
                `${componentName}_first_render_time`,
            );
            records.current.firstRenderTimer.record(elapsedTime);
            records.current.renderCounter = globalMeter.createCounter(
                `${componentName}_render_count`,
                { valueType: ValueType.INT },
            );
            records.current.renderCounter.add(1);
            time.current = performance.now();
            return;
        }
        if (!globalMeter) {
            // Telemetry hasn't been initialized so don't do anything
            return;
        }
        if (!records.current.renderTimer) {
            records.current.renderTimer = globalMeter.createHistogram(
                `${componentName}_render_time`,
            );
        }
        records.current.renderTimer.record(performance.now() - time.current);
        // TODO (Sreeni): Is it worth counting renders with a useState for the else to this if?
        if (records.current.renderCounter) {
            records.current.renderCounter.add(1);
        }
        time.current = performance.now();
    });
};

export const useTracing = (componentName: string) => {
    const tracer = getTracer(componentName);
    const span = tracer.startSpan(`${componentName}_render`);
    useEffect(() => {
        span.end();
    }, []);
};

export const initializeObservability = async () => {
    const observabilityConfig: OBSERVABILITY_CONFIG | any = isFeatureEnabled(
        FEATURE_FLAG_NAMES.OBSERVABILITY_CONFIG,
    );
    const observabilityPromises = [];
    if (!observabilityConfig) {
        return;
    }
    if (
        observabilityConfig?.telemetryEnabled &&
        Math.random() < (observabilityConfig?.telemetrySampleRate ?? 0)
    ) {
        initTelemetry(OTLP_CONFIG);
        observabilityPromises.push(
            registerInstrumentations({
                instrumentations: getWebAutoInstrumentations(),
            }),
        );
    }
    if (observabilityConfig?.replayEnabled) {
        observabilityPromises.push(
            initializeReplay(observabilityConfig?.replaySampleRate ?? 0),
        );
    }
    await Promise.all(observabilityPromises);
};

export * from '@thoughtspot/observability';
